import { Components, Theme } from '@mui/material';

export const MuiSelect: Components<Omit<Theme, 'components'>>['MuiSelect'] = {
  defaultProps: {
    variant: 'outlined',
  },
  styleOverrides: {
    outlined: {
      padding: '7px',
    },
  },
};
