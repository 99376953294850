/**
 * This file was autogenerated and any manual changes will be overwritten eventually.
 * Remove this notice if you move this file out of the src/api/generated/ directory.
 */

import { useAPIHook } from "api/utilities/useAPIHook";
import { useSDKList } from "api/utilities/useAPIList";
import { UsersApi } from "../sdk/apis/UsersApi";
import { configuration } from "./configuration";

export const useUsers = () => {
  const instance = new UsersApi(configuration);

  const createAuctionConsent = useAPIHook(instance.usersAuctionConsentCreate.bind(instance));
  const getBreedingPairingBy = useAPIHook(instance.usersBreedingPairingByRetrieve.bind(instance));
  const createBreedingPairing = useAPIHook(instance.usersBreedingPairingCreate.bind(instance));
  const createBreedingPairingCreateOffspringGroup = useAPIHook(instance.usersBreedingPairingCreateOffspringGroupCreate.bind(instance));
  const destroyBreedingPairing = useAPIHook(instance.usersBreedingPairingDestroy.bind(instance));
  const listBreedingPairing = useAPIHook(instance.usersBreedingPairingList.bind(instance));
  const getBreedingPairingOutcomes = useAPIHook(instance.usersBreedingPairingOutcomesRetrieve.bind(instance));
  const updateBreedingPairingPartial = useAPIHook(instance.usersBreedingPairingPartialUpdate.bind(instance));
  const getBreedingPairing = useAPIHook(instance.usersBreedingPairingRetrieve.bind(instance));
  const updateBreedingPairing = useAPIHook(instance.usersBreedingPairingUpdate.bind(instance));
  const listCollection = useAPIHook(instance.usersCollectionList.bind(instance));
  const getMe = useAPIHook(instance.usersMeRetrieve.bind(instance));
  const createMessageThreadsActions = useAPIHook(instance.usersMessageThreadsActionsCreate.bind(instance));
  const listMessageThreadsActions = useAPIHook(instance.usersMessageThreadsActionsList.bind(instance));
  const updateMessageThreadsActionsMarkSeenPartial = useAPIHook(instance.usersMessageThreadsActionsMarkSeenPartialUpdate.bind(instance));
  const createMessageThreadsBulkActions = useAPIHook(instance.usersMessageThreadsBulkActionsCreate.bind(instance));
  const createMessageThreadsBulkAddTagsToThread = useAPIHook(instance.usersMessageThreadsBulkAddTagsToThreadCreate.bind(instance));
  const createMessageThreadsBulkRemoveTagsFromThreads = useAPIHook(instance.usersMessageThreadsBulkRemoveTagsFromThreadsCreate.bind(instance));
  const listMessageThreadsInquiredAnimals = useAPIHook(instance.usersMessageThreadsInquiredAnimalsList.bind(instance));
  const listMessageThreads = useAPIHook(instance.usersMessageThreadsList.bind(instance));
  const createMessageThreadsMessages = useAPIHook(instance.usersMessageThreadsMessagesCreate.bind(instance));
  const listMessageThreadsMessages = useAPIHook(instance.usersMessageThreadsMessagesList.bind(instance));
  const listMessageThreadsOtherParties = useAPIHook(instance.usersMessageThreadsOtherPartiesList.bind(instance));
  const getMessageThreads = useAPIHook(instance.usersMessageThreadsRetrieve.bind(instance));
  const createMessageThreadsTags = useAPIHook(instance.usersMessageThreadsTagsCreate.bind(instance));
  const destroyMessageThreadsTags = useAPIHook(instance.usersMessageThreadsTagsDestroy.bind(instance));
  const listMessageThreadsTags = useAPIHook(instance.usersMessageThreadsTagsList.bind(instance));
  const updateMessageThreadsTagsUpdateThread = useAPIHook(instance.usersMessageThreadsTagsUpdateThreadUpdate.bind(instance));
  const getMessageThreadsUnseenThreadCounts = useAPIHook(instance.usersMessageThreadsUnseenThreadCountsRetrieve.bind(instance));
  const listOffspringGroups = useAPIHook(instance.usersOffspringGroupsList.bind(instance));
  const getOffspringGroups = useAPIHook(instance.usersOffspringGroupsRetrieve.bind(instance));
  const updatePreferencesPartial = useAPIHook(instance.usersPreferencesPartialUpdate.bind(instance));
  const createThreadTags = useAPIHook(instance.usersThreadTagsCreate.bind(instance));
  const destroyThreadTags = useAPIHook(instance.usersThreadTagsDestroy.bind(instance));
  const listThreadTags = useAPIHook(instance.usersThreadTagsList.bind(instance));
  const updateThreadTagsPartial = useAPIHook(instance.usersThreadTagsPartialUpdate.bind(instance));
  const getThreadTags = useAPIHook(instance.usersThreadTagsRetrieve.bind(instance));
  const updateThreadTags = useAPIHook(instance.usersThreadTagsUpdate.bind(instance));

  const breedingPairing = useSDKList(listBreedingPairing.callback, false);
  const collection = useSDKList(listCollection.callback, false);
  const messageThreadsActions = useSDKList(listMessageThreadsActions.callback, false);
  const messageThreadsInquiredAnimals = useSDKList(listMessageThreadsInquiredAnimals.callback, false);
  const messageThreads = useSDKList(listMessageThreads.callback, false);
  const messageThreadsMessages = useSDKList(listMessageThreadsMessages.callback, false);
  const messageThreadsOtherParties = useSDKList(listMessageThreadsOtherParties.callback, false);
  const messageThreadsTags = useSDKList(listMessageThreadsTags.callback, false);
  const offspringGroups = useSDKList(listOffspringGroups.callback, false);
  const threadTags = useSDKList(listThreadTags.callback, false);

  return {
    error:
      createAuctionConsent.error ||
      getBreedingPairingBy.error ||
      createBreedingPairing.error ||
      createBreedingPairingCreateOffspringGroup.error ||
      destroyBreedingPairing.error ||
      listBreedingPairing.error ||
      getBreedingPairingOutcomes.error ||
      updateBreedingPairingPartial.error ||
      getBreedingPairing.error ||
      updateBreedingPairing.error ||
      listCollection.error ||
      getMe.error ||
      createMessageThreadsActions.error ||
      listMessageThreadsActions.error ||
      updateMessageThreadsActionsMarkSeenPartial.error ||
      createMessageThreadsBulkActions.error ||
      createMessageThreadsBulkAddTagsToThread.error ||
      createMessageThreadsBulkRemoveTagsFromThreads.error ||
      listMessageThreadsInquiredAnimals.error ||
      listMessageThreads.error ||
      createMessageThreadsMessages.error ||
      listMessageThreadsMessages.error ||
      listMessageThreadsOtherParties.error ||
      getMessageThreads.error ||
      createMessageThreadsTags.error ||
      destroyMessageThreadsTags.error ||
      listMessageThreadsTags.error ||
      updateMessageThreadsTagsUpdateThread.error ||
      getMessageThreadsUnseenThreadCounts.error ||
      listOffspringGroups.error ||
      getOffspringGroups.error ||
      updatePreferencesPartial.error ||
      createThreadTags.error ||
      destroyThreadTags.error ||
      listThreadTags.error ||
      updateThreadTagsPartial.error ||
      getThreadTags.error ||
      updateThreadTags.error,
    isLoading:
      createAuctionConsent.isLoading ||
      getBreedingPairingBy.isLoading ||
      createBreedingPairing.isLoading ||
      createBreedingPairingCreateOffspringGroup.isLoading ||
      destroyBreedingPairing.isLoading ||
      listBreedingPairing.isLoading ||
      getBreedingPairingOutcomes.isLoading ||
      updateBreedingPairingPartial.isLoading ||
      getBreedingPairing.isLoading ||
      updateBreedingPairing.isLoading ||
      listCollection.isLoading ||
      getMe.isLoading ||
      createMessageThreadsActions.isLoading ||
      listMessageThreadsActions.isLoading ||
      updateMessageThreadsActionsMarkSeenPartial.isLoading ||
      createMessageThreadsBulkActions.isLoading ||
      createMessageThreadsBulkAddTagsToThread.isLoading ||
      createMessageThreadsBulkRemoveTagsFromThreads.isLoading ||
      listMessageThreadsInquiredAnimals.isLoading ||
      listMessageThreads.isLoading ||
      createMessageThreadsMessages.isLoading ||
      listMessageThreadsMessages.isLoading ||
      listMessageThreadsOtherParties.isLoading ||
      getMessageThreads.isLoading ||
      createMessageThreadsTags.isLoading ||
      destroyMessageThreadsTags.isLoading ||
      listMessageThreadsTags.isLoading ||
      updateMessageThreadsTagsUpdateThread.isLoading ||
      getMessageThreadsUnseenThreadCounts.isLoading ||
      listOffspringGroups.isLoading ||
      getOffspringGroups.isLoading ||
      updatePreferencesPartial.isLoading ||
      createThreadTags.isLoading ||
      destroyThreadTags.isLoading ||
      listThreadTags.isLoading ||
      updateThreadTagsPartial.isLoading ||
      getThreadTags.isLoading ||
      updateThreadTags.isLoading,
    breedingPairing: {
      data: breedingPairing.data,
      parametrizedFetch: breedingPairing.parametrizedFetch,
      refetch: breedingPairing.refetch,
      params: breedingPairing.params,
      setParams: breedingPairing.setParams,
    },
    collection: {
      data: collection.data,
      parametrizedFetch: collection.parametrizedFetch,
      refetch: collection.refetch,
      params: collection.params,
      setParams: collection.setParams,
    },
    messageThreadsActions: {
      data: messageThreadsActions.data,
      parametrizedFetch: messageThreadsActions.parametrizedFetch,
      refetch: messageThreadsActions.refetch,
      params: messageThreadsActions.params,
      setParams: messageThreadsActions.setParams,
    },
    messageThreadsInquiredAnimals: {
      data: messageThreadsInquiredAnimals.data,
      parametrizedFetch: messageThreadsInquiredAnimals.parametrizedFetch,
      refetch: messageThreadsInquiredAnimals.refetch,
      params: messageThreadsInquiredAnimals.params,
      setParams: messageThreadsInquiredAnimals.setParams,
    },
    messageThreads: {
      data: messageThreads.data,
      parametrizedFetch: messageThreads.parametrizedFetch,
      refetch: messageThreads.refetch,
      params: messageThreads.params,
      setParams: messageThreads.setParams,
    },
    messageThreadsMessages: {
      data: messageThreadsMessages.data,
      parametrizedFetch: messageThreadsMessages.parametrizedFetch,
      refetch: messageThreadsMessages.refetch,
      params: messageThreadsMessages.params,
      setParams: messageThreadsMessages.setParams,
    },
    messageThreadsOtherParties: {
      data: messageThreadsOtherParties.data,
      parametrizedFetch: messageThreadsOtherParties.parametrizedFetch,
      refetch: messageThreadsOtherParties.refetch,
      params: messageThreadsOtherParties.params,
      setParams: messageThreadsOtherParties.setParams,
    },
    messageThreadsTags: {
      data: messageThreadsTags.data,
      parametrizedFetch: messageThreadsTags.parametrizedFetch,
      refetch: messageThreadsTags.refetch,
      params: messageThreadsTags.params,
      setParams: messageThreadsTags.setParams,
    },
    offspringGroups: {
      data: offspringGroups.data,
      parametrizedFetch: offspringGroups.parametrizedFetch,
      refetch: offspringGroups.refetch,
      params: offspringGroups.params,
      setParams: offspringGroups.setParams,
    },
    threadTags: {
      data: threadTags.data,
      parametrizedFetch: threadTags.parametrizedFetch,
      refetch: threadTags.refetch,
      params: threadTags.params,
      setParams: threadTags.setParams,
    },

    createAuctionConsent: createAuctionConsent.callback,
    getBreedingPairingBy: getBreedingPairingBy.callback,
    createBreedingPairing: createBreedingPairing.callback,
    createBreedingPairingCreateOffspringGroup: createBreedingPairingCreateOffspringGroup.callback,
    destroyBreedingPairing: destroyBreedingPairing.callback,
    listBreedingPairing: listBreedingPairing.callback,
    getBreedingPairingOutcomes: getBreedingPairingOutcomes.callback,
    updateBreedingPairingPartial: updateBreedingPairingPartial.callback,
    getBreedingPairing: getBreedingPairing.callback,
    updateBreedingPairing: updateBreedingPairing.callback,
    listCollection: listCollection.callback,
    getMe: getMe.callback,
    createMessageThreadsActions: createMessageThreadsActions.callback,
    listMessageThreadsActions: listMessageThreadsActions.callback,
    updateMessageThreadsActionsMarkSeenPartial: updateMessageThreadsActionsMarkSeenPartial.callback,
    createMessageThreadsBulkActions: createMessageThreadsBulkActions.callback,
    createMessageThreadsBulkAddTagsToThread: createMessageThreadsBulkAddTagsToThread.callback,
    createMessageThreadsBulkRemoveTagsFromThreads: createMessageThreadsBulkRemoveTagsFromThreads.callback,
    listMessageThreadsInquiredAnimals: listMessageThreadsInquiredAnimals.callback,
    listMessageThreads: listMessageThreads.callback,
    createMessageThreadsMessages: createMessageThreadsMessages.callback,
    listMessageThreadsMessages: listMessageThreadsMessages.callback,
    listMessageThreadsOtherParties: listMessageThreadsOtherParties.callback,
    getMessageThreads: getMessageThreads.callback,
    createMessageThreadsTags: createMessageThreadsTags.callback,
    destroyMessageThreadsTags: destroyMessageThreadsTags.callback,
    listMessageThreadsTags: listMessageThreadsTags.callback,
    updateMessageThreadsTagsUpdateThread: updateMessageThreadsTagsUpdateThread.callback,
    getMessageThreadsUnseenThreadCounts: getMessageThreadsUnseenThreadCounts.callback,
    listOffspringGroups: listOffspringGroups.callback,
    getOffspringGroups: getOffspringGroups.callback,
    updatePreferencesPartial: updatePreferencesPartial.callback,
    createThreadTags: createThreadTags.callback,
    destroyThreadTags: destroyThreadTags.callback,
    listThreadTags: listThreadTags.callback,
    updateThreadTagsPartial: updateThreadTagsPartial.callback,
    getThreadTags: getThreadTags.callback,
    updateThreadTags: updateThreadTags.callback,
  };
};
