import { createTheme } from '@mui/material';
import {
  MuiButton,
  MuiIconButton,
  MuiTextField,
  MuiCheckbox,
  MuiSelect,
  MuiMenu,
  MuiMenuItem,
  MuiOutlinedInput,
  MuiAutocomplete,
  MuiInputLabel,
  MuiFormControl,
  MuiInputBase,
  MuiLink,
  MuiSwitch,
  MuiPopover,
  MuiSnackbarContent,
  MuiChip,
  MuiSlider,
} from './overrides';
import breakpoint from 'style/breakpoints.scss';
import space from 'style/spacing.scss';
import fontSizes from 'style/typo.scss';

export const base = createTheme({
  spacing: (x: number | string) => (typeof x === 'number' ? `${8 * x}px` : space[x] ?? x),
  breakpoints: {
    values: {
      xs: 0,
      mobileMedium: parseInt(breakpoint.mobileMedium, 10),
      mobileLarge: parseInt(breakpoint.mobileLarge, 10),
      tablet: parseInt(breakpoint.tablet, 10),
      tabletLarge: parseInt(breakpoint.tabletLarge, 10),
      desktop: parseInt(breakpoint.desktop, 10),
      desktopLarge: parseInt(breakpoint.desktopLarge, 10),
    },
  },
  typography: {
    fontFamily: '"Outfit", sans-serif',
    fontWeightBold: 700,

    xxxs: { fontSize: fontSizes.f_xxxs },
    xxs: { fontSize: fontSizes.f_xxs },
    xs: { fontSize: fontSizes.f_xs },
    sm: { fontSize: fontSizes.f_sm },
    md: { fontSize: fontSizes.f_md },
    lg: { fontSize: fontSizes.f_lg },
    xl: { fontSize: fontSizes.f_xl },
    xxl: { fontSize: fontSizes.f_xxl },
    xxxl: { fontSize: fontSizes.f_xxxl },
  },
  components: {
    MuiLink,
    MuiChip,
    MuiAutocomplete,
    MuiButton,
    MuiIconButton,
    MuiTextField,
    MuiCheckbox,
    MuiSelect,
    MuiMenu,
    MuiMenuItem,
    MuiOutlinedInput,
    MuiFormControl,
    MuiInputLabel,
    MuiSwitch,
    MuiInputBase,
    MuiPopover,
    MuiSnackbarContent,
    MuiSlider,
  },
});
