/* tslint:disable */
/* eslint-disable */
/**
 * Morphmarket APIs
 * API documentation generated by DRF spectacular
 *
 * The version of the OpenAPI document: 1.0.0 (v1)
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AcceptTradesDisplayEnum,
    AcceptTradesDisplayEnumFromJSON,
    AcceptTradesDisplayEnumFromJSONTyped,
    AcceptTradesDisplayEnumToJSON,
} from './AcceptTradesDisplayEnum';
import {
    ActiveEnum,
    ActiveEnumFromJSON,
    ActiveEnumFromJSONTyped,
    ActiveEnumToJSON,
} from './ActiveEnum';
import {
    AdImage,
    AdImageFromJSON,
    AdImageFromJSONTyped,
    AdImageToJSON,
} from './AdImage';
import {
    AnimalAcceptTrades,
    AnimalAcceptTradesFromJSON,
    AnimalAcceptTradesFromJSONTyped,
    AnimalAcceptTradesToJSON,
} from './AnimalAcceptTrades';
import {
    AnimalAuction,
    AnimalAuctionFromJSON,
    AnimalAuctionFromJSONTyped,
    AnimalAuctionToJSON,
} from './AnimalAuction';
import {
    AnimalItemOrigin,
    AnimalItemOriginFromJSON,
    AnimalItemOriginFromJSONTyped,
    AnimalItemOriginToJSON,
} from './AnimalItemOrigin';
import {
    AnimalMaturity,
    AnimalMaturityFromJSON,
    AnimalMaturityFromJSONTyped,
    AnimalMaturityToJSON,
} from './AnimalMaturity';
import {
    AnimalPreyFood,
    AnimalPreyFoodFromJSON,
    AnimalPreyFoodFromJSONTyped,
    AnimalPreyFoodToJSON,
} from './AnimalPreyFood';
import {
    AnimalPreyStatus,
    AnimalPreyStatusFromJSON,
    AnimalPreyStatusFromJSONTyped,
    AnimalPreyStatusToJSON,
} from './AnimalPreyStatus';
import {
    AnimalPriceFlexibility,
    AnimalPriceFlexibilityFromJSON,
    AnimalPriceFlexibilityFromJSONTyped,
    AnimalPriceFlexibilityToJSON,
} from './AnimalPriceFlexibility';
import {
    AnimalSex,
    AnimalSexFromJSON,
    AnimalSexFromJSONTyped,
    AnimalSexToJSON,
} from './AnimalSex';
import {
    AnimalTag,
    AnimalTagFromJSON,
    AnimalTagFromJSONTyped,
    AnimalTagToJSON,
} from './AnimalTag';
import {
    AnimalTrait,
    AnimalTraitFromJSON,
    AnimalTraitFromJSONTyped,
    AnimalTraitToJSON,
} from './AnimalTrait';
import {
    ApproximateDate,
    ApproximateDateFromJSON,
    ApproximateDateFromJSONTyped,
    ApproximateDateToJSON,
} from './ApproximateDate';
import {
    ImageVariants,
    ImageVariantsFromJSON,
    ImageVariantsFromJSONTyped,
    ImageVariantsToJSON,
} from './ImageVariants';
import {
    IndustryEventMicro,
    IndustryEventMicroFromJSON,
    IndustryEventMicroFromJSONTyped,
    IndustryEventMicroToJSON,
} from './IndustryEventMicro';
import {
    ItemOriginDisplayEnum,
    ItemOriginDisplayEnumFromJSON,
    ItemOriginDisplayEnumFromJSONTyped,
    ItemOriginDisplayEnumToJSON,
} from './ItemOriginDisplayEnum';
import {
    LengthTypeDisplayEnum,
    LengthTypeDisplayEnumFromJSON,
    LengthTypeDisplayEnumFromJSONTyped,
    LengthTypeDisplayEnumToJSON,
} from './LengthTypeDisplayEnum';
import {
    LengthTypeEnum,
    LengthTypeEnumFromJSON,
    LengthTypeEnumFromJSONTyped,
    LengthTypeEnumToJSON,
} from './LengthTypeEnum';
import {
    MaturityDisplayEnum,
    MaturityDisplayEnumFromJSON,
    MaturityDisplayEnumFromJSONTyped,
    MaturityDisplayEnumToJSON,
} from './MaturityDisplayEnum';
import {
    MinimumOfferEnum,
    MinimumOfferEnumFromJSON,
    MinimumOfferEnumFromJSONTyped,
    MinimumOfferEnumToJSON,
} from './MinimumOfferEnum';
import {
    Parent,
    ParentFromJSON,
    ParentFromJSONTyped,
    ParentToJSON,
} from './Parent';
import {
    PreyFoodDisplayEnum,
    PreyFoodDisplayEnumFromJSON,
    PreyFoodDisplayEnumFromJSONTyped,
    PreyFoodDisplayEnumToJSON,
} from './PreyFoodDisplayEnum';
import {
    PreyStatusDisplayEnum,
    PreyStatusDisplayEnumFromJSON,
    PreyStatusDisplayEnumFromJSONTyped,
    PreyStatusDisplayEnumToJSON,
} from './PreyStatusDisplayEnum';
import {
    PriceFlexibilityDisplayEnum,
    PriceFlexibilityDisplayEnumFromJSON,
    PriceFlexibilityDisplayEnumFromJSONTyped,
    PriceFlexibilityDisplayEnumToJSON,
} from './PriceFlexibilityDisplayEnum';
import {
    SexDisplayEnum,
    SexDisplayEnumFromJSON,
    SexDisplayEnumFromJSONTyped,
    SexDisplayEnumToJSON,
} from './SexDisplayEnum';
import {
    ShippingTypeEnum,
    ShippingTypeEnumFromJSON,
    ShippingTypeEnumFromJSONTyped,
    ShippingTypeEnumToJSON,
} from './ShippingTypeEnum';
import {
    StateDisplayEnum,
    StateDisplayEnumFromJSON,
    StateDisplayEnumFromJSONTyped,
    StateDisplayEnumToJSON,
} from './StateDisplayEnum';
import {
    StateEnum,
    StateEnumFromJSON,
    StateEnumFromJSONTyped,
    StateEnumToJSON,
} from './StateEnum';
import {
    VisibilityEnum,
    VisibilityEnumFromJSON,
    VisibilityEnumFromJSONTyped,
    VisibilityEnumToJSON,
} from './VisibilityEnum';

/**
 * Animal Serializer
 * @export
 * @interface Animal
 */
export interface Animal {
    /**
     * 
     * @type {string}
     * @memberof Animal
     */
    readonly url: string;
    /**
     * 
     * @type {number}
     * @memberof Animal
     */
    key?: number;
    /**
     * 
     * @type {string}
     * @memberof Animal
     */
    readonly path: string;
    /**
     * 
     * @type {Array<ImageVariants>}
     * @memberof Animal
     */
    readonly thumbImage: Array<ImageVariants> | null;
    /**
     * 
     * @type {Array<AdImage>}
     * @memberof Animal
     */
    images?: Array<AdImage>;
    /**
     * 
     * @type {boolean}
     * @memberof Animal
     */
    exactItemsAreAllPictured?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Animal
     */
    readonly birthDateDisplay: string | null;
    /**
     * 
     * @type {ApproximateDate}
     * @memberof Animal
     */
    birthDate?: ApproximateDate;
    /**
     * 
     * @type {SexDisplayEnum}
     * @memberof Animal
     */
    readonly sexDisplay: SexDisplayEnum | null;
    /**
     * 
     * @type {AnimalSex}
     * @memberof Animal
     */
    sex?: AnimalSex | null;
    /**
     * 
     * @type {MaturityDisplayEnum}
     * @memberof Animal
     */
    readonly maturityDisplay: MaturityDisplayEnum | null;
    /**
     * 
     * @type {AnimalMaturity}
     * @memberof Animal
     */
    maturity?: AnimalMaturity | null;
    /**
     * 
     * @type {string}
     * @memberof Animal
     */
    readonly titleDisplay: string;
    /**
     * 
     * @type {string}
     * @memberof Animal
     */
    title: string;
    /**
     * 
     * @type {string}
     * @memberof Animal
     */
    readonly categoryDisplay: string;
    /**
     * 
     * @type {string}
     * @memberof Animal
     */
    category: string;
    /**
     * 
     * @type {number}
     * @memberof Animal
     */
    readonly categoryId: number;
    /**
     * 
     * @type {string}
     * @memberof Animal
     */
    readonly weightDisplay: string | null;
    /**
     * 
     * @type {number}
     * @memberof Animal
     */
    weight?: number | null;
    /**
     * 
     * @type {number}
     * @memberof Animal
     */
    price?: number;
    /**
     * 
     * @type {string}
     * @memberof Animal
     */
    readonly priceDisplay: string;
    /**
     * 
     * @type {number}
     * @memberof Animal
     */
    salePrice?: number | null;
    /**
     * 
     * @type {string}
     * @memberof Animal
     */
    readonly salePriceDisplay: string | null;
    /**
     * 
     * @type {string}
     * @memberof Animal
     */
    readonly saleEndTime: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof Animal
     */
    readonly isOnSale: boolean;
    /**
     * sale start is allowed if:
     *     1. ad is not on sale
     *  2. ad is live
     *  3. AND:
     *      a. sale_start_timestamp is None (ad has never been on sale) OR
     *    b. sale_start_timestamp + sale_max_period_days is in the future (sale period has not been ended yet) OR
     *    c. sale_start_timestamp < last_renewal (ad had not been on sale in the current renewal cycle)
     * @type {boolean}
     * @memberof Animal
     */
    readonly isSaleStartAllowed: boolean;
    /**
     * 
     * @type {number}
     * @memberof Animal
     */
    readonly saleRenewalAllowedInDays: number | null;
    /**
     * 
     * @type {string}
     * @memberof Animal
     */
    readonly priceCurrency: string;
    /**
     * 
     * @type {boolean}
     * @memberof Animal
     */
    inquireForPrice?: boolean;
    /**
     * 
     * @type {PreyFoodDisplayEnum}
     * @memberof Animal
     */
    readonly preyFoodDisplay: PreyFoodDisplayEnum | null;
    /**
     * 
     * @type {AnimalPreyFood}
     * @memberof Animal
     */
    preyFood?: AnimalPreyFood | null;
    /**
     * 
     * @type {PreyStatusDisplayEnum}
     * @memberof Animal
     */
    readonly preyStatusDisplay: PreyStatusDisplayEnum | null;
    /**
     * 
     * @type {AnimalPreyStatus}
     * @memberof Animal
     */
    preyStatus?: AnimalPreyStatus | null;
    /**
     * 
     * @type {boolean}
     * @memberof Animal
     */
    petOnly?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Animal
     */
    readonly firstListed: string;
    /**
     * 
     * @type {string}
     * @memberof Animal
     */
    readonly lastUpdated: string;
    /**
     * 
     * @type {number}
     * @memberof Animal
     */
    readonly quantityDisplay: number;
    /**
     * 
     * @type {number}
     * @memberof Animal
     */
    quantity?: number;
    /**
     * 
     * @type {number}
     * @memberof Animal
     */
    readonly traitCount: number;
    /**
     * 
     * @type {Array<AnimalTrait>}
     * @memberof Animal
     */
    traits?: Array<AnimalTrait>;
    /**
     * 
     * @type {Array<AnimalTag>}
     * @memberof Animal
     */
    tags?: Array<AnimalTag>;
    /**
     * 
     * @type {Date}
     * @memberof Animal
     */
    lastRenewal?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof Animal
     */
    animalId?: string;
    /**
     * 
     * @type {string}
     * @memberof Animal
     */
    clutchNumber?: string;
    /**
     * 
     * @type {number}
     * @memberof Animal
     */
    readonly impressionCount: number;
    /**
     * 
     * @type {number}
     * @memberof Animal
     */
    readonly clickCount: number;
    /**
     * 
     * @type {number}
     * @memberof Animal
     */
    readonly likeCount: number;
    /**
     * 
     * @type {string}
     * @memberof Animal
     */
    expiredMessage?: string;
    /**
     * 
     * @type {number}
     * @memberof Animal
     */
    readonly inquiriesCount: number | null;
    /**
     * 
     * @type {string}
     * @memberof Animal
     */
    readonly viewUrl: string;
    /**
     * 
     * @type {string}
     * @memberof Animal
     */
    readonly cloneUrl: string | null;
    /**
     * 
     * @type {string}
     * @memberof Animal
     */
    readonly editUrl: string | null;
    /**
     * 
     * @type {string}
     * @memberof Animal
     */
    readonly removeUrl: string | null;
    /**
     * 
     * @type {string}
     * @memberof Animal
     */
    readonly renewUrl: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof Animal
     */
    readonly canRenew: boolean;
    /**
     * 
     * @type {Date}
     * @memberof Animal
     */
    readonly canRenewOn: Date;
    /**
     * 
     * @type {boolean}
     * @memberof Animal
     */
    readonly canEdit: boolean;
    /**
     * 
     * @type {StateDisplayEnum}
     * @memberof Animal
     */
    readonly stateDisplay: StateDisplayEnum | null;
    /**
     * 
     * @type {StateEnum}
     * @memberof Animal
     */
    state: StateEnum;
    /**
     * 
     * @type {ActiveEnum}
     * @memberof Animal
     */
    active: ActiveEnum;
    /**
     * 
     * @type {VisibilityEnum}
     * @memberof Animal
     */
    visibility: VisibilityEnum;
    /**
     * 
     * @type {string}
     * @memberof Animal
     */
    readonly buyerDisplay: string | null;
    /**
     * 
     * @type {string}
     * @memberof Animal
     */
    buyer?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Animal
     */
    soldMethod?: string | null;
    /**
     * 
     * @type {Array<Parent>}
     * @memberof Animal
     */
    dams?: Array<Parent>;
    /**
     * 
     * @type {Array<Parent>}
     * @memberof Animal
     */
    sires?: Array<Parent>;
    /**
     * 
     * @type {boolean}
     * @memberof Animal
     */
    readonly isActiveBreeder: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof Animal
     */
    isGravid?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Animal
     */
    showSiresAndDamsOnlyToMe?: boolean;
    /**
     * 
     * @type {ItemOriginDisplayEnum}
     * @memberof Animal
     */
    readonly itemOriginDisplay: ItemOriginDisplayEnum | null;
    /**
     * 
     * @type {AnimalItemOrigin}
     * @memberof Animal
     */
    itemOrigin?: AnimalItemOrigin | null;
    /**
     * 
     * @type {string}
     * @memberof Animal
     */
    description?: string | null;
    /**
     * 
     * @type {ShippingTypeEnum}
     * @memberof Animal
     */
    shippingType: ShippingTypeEnum;
    /**
     * 
     * @type {number}
     * @memberof Animal
     */
    minShipping?: number | null;
    /**
     * 
     * @type {number}
     * @memberof Animal
     */
    maxShipping?: number | null;
    /**
     * 
     * @type {number}
     * @memberof Animal
     */
    readonly fixedShipping: number | null;
    /**
     * 
     * @type {number}
     * @memberof Animal
     */
    readonly maxPhotosPerAd: number;
    /**
     * 
     * @type {boolean}
     * @memberof Animal
     */
    provenBreeder?: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof Animal
     */
    length?: number | null;
    /**
     * 
     * @type {LengthTypeDisplayEnum}
     * @memberof Animal
     */
    readonly lengthTypeDisplay: LengthTypeDisplayEnum | null;
    /**
     * 
     * @type {LengthTypeEnum}
     * @memberof Animal
     */
    lengthType?: LengthTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof Animal
     */
    videoUrl?: string;
    /**
     * 
     * @type {PriceFlexibilityDisplayEnum}
     * @memberof Animal
     */
    readonly priceFlexibilityDisplay: PriceFlexibilityDisplayEnum | null;
    /**
     * 
     * @type {AnimalPriceFlexibility}
     * @memberof Animal
     */
    priceFlexibility?: AnimalPriceFlexibility | null;
    /**
     * 
     * @type {AcceptTradesDisplayEnum}
     * @memberof Animal
     */
    readonly acceptTradesDisplay: AcceptTradesDisplayEnum | null;
    /**
     * 
     * @type {AnimalAcceptTrades}
     * @memberof Animal
     */
    acceptTrades?: AnimalAcceptTrades | null;
    /**
     * 
     * @type {boolean}
     * @memberof Animal
     */
    soldAsGroup?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Animal
     */
    assignedValue?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Animal
     */
    notes?: string;
    /**
     * 
     * @type {Array<IndustryEventMicro>}
     * @memberof Animal
     */
    events: Array<IndustryEventMicro>;
    /**
     * 
     * @type {Array<IndustryEventMicro>}
     * @memberof Animal
     */
    readonly eventsWithDetail: Array<IndustryEventMicro>;
    /**
     * 
     * @type {boolean}
     * @memberof Animal
     */
    availableForOrder?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Animal
     */
    acceptOffers?: boolean;
    /**
     * 
     * @type {MinimumOfferEnum}
     * @memberof Animal
     */
    minimumOffer?: MinimumOfferEnum;
    /**
     * 
     * @type {boolean}
     * @memberof Animal
     */
    readonly needsUpdate: boolean;
    /**
     * 
     * @type {string}
     * @memberof Animal
     */
    readonly transferredFrom: string | null;
    /**
     * 
     * @type {number}
     * @memberof Animal
     */
    wholesalePrice?: number | null;
    /**
     * 
     * @type {string}
     * @memberof Animal
     */
    readonly wholesalePriceDisplay: string | null;
    /**
     * 
     * @type {string}
     * @memberof Animal
     */
    wholesaleDescription?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof Animal
     */
    wholesaleOnly?: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof Animal
     */
    wholesaleFilter?: Array<string>;
    /**
     * 
     * @type {AnimalAuction}
     * @memberof Animal
     */
    auction?: AnimalAuction | null;
}

export function AnimalFromJSON(json: any): Animal {
    return AnimalFromJSONTyped(json, false);
}

export function AnimalFromJSONTyped(json: any, ignoreDiscriminator: boolean): Animal {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'url': json['url'],
        'key': !exists(json, 'key') ? undefined : json['key'],
        'path': json['path'],
        'thumbImage': (json['thumb_image'] === null ? null : (json['thumb_image'] as Array<any>).map(ImageVariantsFromJSON)),
        'images': !exists(json, 'images') ? undefined : ((json['images'] as Array<any>).map(AdImageFromJSON)),
        'exactItemsAreAllPictured': !exists(json, 'exact_items_are_all_pictured') ? undefined : json['exact_items_are_all_pictured'],
        'birthDateDisplay': json['birth_date_display'],
        'birthDate': !exists(json, 'birth_date') ? undefined : ApproximateDateFromJSON(json['birth_date']),
        'sexDisplay': SexDisplayEnumFromJSON(json['sex_display']),
        'sex': !exists(json, 'sex') ? undefined : AnimalSexFromJSON(json['sex']),
        'maturityDisplay': MaturityDisplayEnumFromJSON(json['maturity_display']),
        'maturity': !exists(json, 'maturity') ? undefined : AnimalMaturityFromJSON(json['maturity']),
        'titleDisplay': json['title_display'],
        'title': json['title'],
        'categoryDisplay': json['category_display'],
        'category': json['category'],
        'categoryId': json['category_id'],
        'weightDisplay': json['weight_display'],
        'weight': !exists(json, 'weight') ? undefined : json['weight'],
        'price': !exists(json, 'price') ? undefined : json['price'],
        'priceDisplay': json['price_display'],
        'salePrice': !exists(json, 'sale_price') ? undefined : json['sale_price'],
        'salePriceDisplay': json['sale_price_display'],
        'saleEndTime': json['sale_end_time'],
        'isOnSale': json['is_on_sale'],
        'isSaleStartAllowed': json['is_sale_start_allowed'],
        'saleRenewalAllowedInDays': json['sale_renewal_allowed_in_days'],
        'priceCurrency': json['price_currency'],
        'inquireForPrice': !exists(json, 'inquire_for_price') ? undefined : json['inquire_for_price'],
        'preyFoodDisplay': PreyFoodDisplayEnumFromJSON(json['prey_food_display']),
        'preyFood': !exists(json, 'prey_food') ? undefined : AnimalPreyFoodFromJSON(json['prey_food']),
        'preyStatusDisplay': PreyStatusDisplayEnumFromJSON(json['prey_status_display']),
        'preyStatus': !exists(json, 'prey_status') ? undefined : AnimalPreyStatusFromJSON(json['prey_status']),
        'petOnly': !exists(json, 'pet_only') ? undefined : json['pet_only'],
        'firstListed': json['first_listed'],
        'lastUpdated': json['last_updated'],
        'quantityDisplay': json['quantity_display'],
        'quantity': !exists(json, 'quantity') ? undefined : json['quantity'],
        'traitCount': json['trait_count'],
        'traits': !exists(json, 'traits') ? undefined : ((json['traits'] as Array<any>).map(AnimalTraitFromJSON)),
        'tags': !exists(json, 'tags') ? undefined : ((json['tags'] as Array<any>).map(AnimalTagFromJSON)),
        'lastRenewal': !exists(json, 'last_renewal') ? undefined : (json['last_renewal'] === null ? null : new Date(json['last_renewal'])),
        'animalId': !exists(json, 'animal_id') ? undefined : json['animal_id'],
        'clutchNumber': !exists(json, 'clutch_number') ? undefined : json['clutch_number'],
        'impressionCount': json['impression_count'],
        'clickCount': json['click_count'],
        'likeCount': json['like_count'],
        'expiredMessage': !exists(json, 'expired_message') ? undefined : json['expired_message'],
        'inquiriesCount': json['inquiries_count'],
        'viewUrl': json['view_url'],
        'cloneUrl': json['clone_url'],
        'editUrl': json['edit_url'],
        'removeUrl': json['remove_url'],
        'renewUrl': json['renew_url'],
        'canRenew': json['can_renew'],
        'canRenewOn': (new Date(json['can_renew_on'])),
        'canEdit': json['can_edit'],
        'stateDisplay': StateDisplayEnumFromJSON(json['state_display']),
        'state': StateEnumFromJSON(json['state']),
        'active': ActiveEnumFromJSON(json['active']),
        'visibility': VisibilityEnumFromJSON(json['visibility']),
        'buyerDisplay': json['buyer_display'],
        'buyer': !exists(json, 'buyer') ? undefined : json['buyer'],
        'soldMethod': !exists(json, 'sold_method') ? undefined : json['sold_method'],
        'dams': !exists(json, 'dams') ? undefined : ((json['dams'] as Array<any>).map(ParentFromJSON)),
        'sires': !exists(json, 'sires') ? undefined : ((json['sires'] as Array<any>).map(ParentFromJSON)),
        'isActiveBreeder': json['is_active_breeder'],
        'isGravid': !exists(json, 'is_gravid') ? undefined : json['is_gravid'],
        'showSiresAndDamsOnlyToMe': !exists(json, 'show_sires_and_dams_only_to_me') ? undefined : json['show_sires_and_dams_only_to_me'],
        'itemOriginDisplay': ItemOriginDisplayEnumFromJSON(json['item_origin_display']),
        'itemOrigin': !exists(json, 'item_origin') ? undefined : AnimalItemOriginFromJSON(json['item_origin']),
        'description': !exists(json, 'description') ? undefined : json['description'],
        'shippingType': ShippingTypeEnumFromJSON(json['shipping_type']),
        'minShipping': !exists(json, 'min_shipping') ? undefined : json['min_shipping'],
        'maxShipping': !exists(json, 'max_shipping') ? undefined : json['max_shipping'],
        'fixedShipping': json['fixed_shipping'],
        'maxPhotosPerAd': json['max_photos_per_ad'],
        'provenBreeder': !exists(json, 'proven_breeder') ? undefined : json['proven_breeder'],
        'length': !exists(json, 'length') ? undefined : json['length'],
        'lengthTypeDisplay': LengthTypeDisplayEnumFromJSON(json['length_type_display']),
        'lengthType': !exists(json, 'length_type') ? undefined : LengthTypeEnumFromJSON(json['length_type']),
        'videoUrl': !exists(json, 'video_url') ? undefined : json['video_url'],
        'priceFlexibilityDisplay': PriceFlexibilityDisplayEnumFromJSON(json['price_flexibility_display']),
        'priceFlexibility': !exists(json, 'price_flexibility') ? undefined : AnimalPriceFlexibilityFromJSON(json['price_flexibility']),
        'acceptTradesDisplay': AcceptTradesDisplayEnumFromJSON(json['accept_trades_display']),
        'acceptTrades': !exists(json, 'accept_trades') ? undefined : AnimalAcceptTradesFromJSON(json['accept_trades']),
        'soldAsGroup': !exists(json, 'sold_as_group') ? undefined : json['sold_as_group'],
        'assignedValue': !exists(json, 'assigned_value') ? undefined : json['assigned_value'],
        'notes': !exists(json, 'notes') ? undefined : json['notes'],
        'events': ((json['events'] as Array<any>).map(IndustryEventMicroFromJSON)),
        'eventsWithDetail': ((json['events_with_detail'] as Array<any>).map(IndustryEventMicroFromJSON)),
        'availableForOrder': !exists(json, 'available_for_order') ? undefined : json['available_for_order'],
        'acceptOffers': !exists(json, 'accept_offers') ? undefined : json['accept_offers'],
        'minimumOffer': !exists(json, 'minimum_offer') ? undefined : MinimumOfferEnumFromJSON(json['minimum_offer']),
        'needsUpdate': json['needs_update'],
        'transferredFrom': json['transferred_from'],
        'wholesalePrice': !exists(json, 'wholesale_price') ? undefined : json['wholesale_price'],
        'wholesalePriceDisplay': json['wholesale_price_display'],
        'wholesaleDescription': !exists(json, 'wholesale_description') ? undefined : json['wholesale_description'],
        'wholesaleOnly': !exists(json, 'wholesale_only') ? undefined : json['wholesale_only'],
        'wholesaleFilter': !exists(json, 'wholesale_filter') ? undefined : json['wholesale_filter'],
        'auction': !exists(json, 'auction') ? undefined : AnimalAuctionFromJSON(json['auction']),
    };
}

export function AnimalToJSON(value?: Animal | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'key': value.key,
        'images': value.images === undefined ? undefined : ((value.images as Array<any>).map(AdImageToJSON)),
        'exact_items_are_all_pictured': value.exactItemsAreAllPictured,
        'birth_date': ApproximateDateToJSON(value.birthDate),
        'sex': AnimalSexToJSON(value.sex),
        'maturity': AnimalMaturityToJSON(value.maturity),
        'title': value.title,
        'category': value.category,
        'weight': value.weight,
        'price': value.price,
        'sale_price': value.salePrice,
        'inquire_for_price': value.inquireForPrice,
        'prey_food': AnimalPreyFoodToJSON(value.preyFood),
        'prey_status': AnimalPreyStatusToJSON(value.preyStatus),
        'pet_only': value.petOnly,
        'quantity': value.quantity,
        'traits': value.traits === undefined ? undefined : ((value.traits as Array<any>).map(AnimalTraitToJSON)),
        'tags': value.tags === undefined ? undefined : ((value.tags as Array<any>).map(AnimalTagToJSON)),
        'last_renewal': value.lastRenewal === undefined ? undefined : (value.lastRenewal === null ? null : value.lastRenewal.toISOString()),
        'animal_id': value.animalId,
        'clutch_number': value.clutchNumber,
        'expired_message': value.expiredMessage,
        'state': StateEnumToJSON(value.state),
        'active': ActiveEnumToJSON(value.active),
        'visibility': VisibilityEnumToJSON(value.visibility),
        'buyer': value.buyer,
        'sold_method': value.soldMethod,
        'dams': value.dams === undefined ? undefined : ((value.dams as Array<any>).map(ParentToJSON)),
        'sires': value.sires === undefined ? undefined : ((value.sires as Array<any>).map(ParentToJSON)),
        'is_gravid': value.isGravid,
        'show_sires_and_dams_only_to_me': value.showSiresAndDamsOnlyToMe,
        'item_origin': AnimalItemOriginToJSON(value.itemOrigin),
        'description': value.description,
        'shipping_type': ShippingTypeEnumToJSON(value.shippingType),
        'min_shipping': value.minShipping,
        'max_shipping': value.maxShipping,
        'proven_breeder': value.provenBreeder,
        'length': value.length,
        'length_type': LengthTypeEnumToJSON(value.lengthType),
        'video_url': value.videoUrl,
        'price_flexibility': AnimalPriceFlexibilityToJSON(value.priceFlexibility),
        'accept_trades': AnimalAcceptTradesToJSON(value.acceptTrades),
        'sold_as_group': value.soldAsGroup,
        'assigned_value': value.assignedValue,
        'notes': value.notes,
        'events': ((value.events as Array<any>).map(IndustryEventMicroToJSON)),
        'available_for_order': value.availableForOrder,
        'accept_offers': value.acceptOffers,
        'minimum_offer': MinimumOfferEnumToJSON(value.minimumOffer),
        'wholesale_price': value.wholesalePrice,
        'wholesale_description': value.wholesaleDescription,
        'wholesale_only': value.wholesaleOnly,
        'wholesale_filter': value.wholesaleFilter,
        'auction': AnimalAuctionToJSON(value.auction),
    };
}

