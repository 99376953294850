/* tslint:disable */
/* eslint-disable */
/**
 * Morphmarket APIs
 * API documentation generated by DRF spectacular
 *
 * The version of the OpenAPI document: 1.0.0 (v1)
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from "../runtime";
import {
  AbstractUser,
  AbstractUserFromJSON,
  AbstractUserToJSON,
  ActionResponse,
  ActionResponseFromJSON,
  ActionResponseToJSON,
  AnonymousUser,
  AnonymousUserFromJSON,
  AnonymousUserToJSON,
  AvailableAction,
  AvailableActionFromJSON,
  AvailableActionToJSON,
  BreedingPairingDetails,
  BreedingPairingDetailsFromJSON,
  BreedingPairingDetailsToJSON,
  BreedingPairingDetailsRequest,
  BreedingPairingDetailsRequestFromJSON,
  BreedingPairingDetailsRequestToJSON,
  BreedingPairingListByPrimaryAnimal,
  BreedingPairingListByPrimaryAnimalFromJSON,
  BreedingPairingListByPrimaryAnimalToJSON,
  InquiredAnimal,
  InquiredAnimalFromJSON,
  InquiredAnimalToJSON,
  MarkSeenResponse,
  MarkSeenResponseFromJSON,
  MarkSeenResponseToJSON,
  MessangerActionProxyRequest,
  MessangerActionProxyRequestFromJSON,
  MessangerActionProxyRequestToJSON,
  NewMessageRequest,
  NewMessageRequestFromJSON,
  NewMessageRequestToJSON,
  NewMessageResponse,
  NewMessageResponseFromJSON,
  NewMessageResponseToJSON,
  OffspringGroup,
  OffspringGroupFromJSON,
  OffspringGroupToJSON,
  OtherParty,
  OtherPartyFromJSON,
  OtherPartyToJSON,
  PaginatedBreedingPairingListList,
  PaginatedBreedingPairingListListFromJSON,
  PaginatedBreedingPairingListListToJSON,
  PaginatedMessageList,
  PaginatedMessageListFromJSON,
  PaginatedMessageListToJSON,
  PaginatedOffspringGroupListList,
  PaginatedOffspringGroupListListFromJSON,
  PaginatedOffspringGroupListListToJSON,
  PaginatedPublicAnimalList,
  PaginatedPublicAnimalListFromJSON,
  PaginatedPublicAnimalListToJSON,
  PaginatedThreadList,
  PaginatedThreadListFromJSON,
  PaginatedThreadListToJSON,
  PatchedBreedingPairingDetailsRequest,
  PatchedBreedingPairingDetailsRequestFromJSON,
  PatchedBreedingPairingDetailsRequestToJSON,
  PatchedUserModelPreferencesRequest,
  PatchedUserModelPreferencesRequestFromJSON,
  PatchedUserModelPreferencesRequestToJSON,
  PatchedUserThreadTagRequest,
  PatchedUserThreadTagRequestFromJSON,
  PatchedUserThreadTagRequestToJSON,
  TagAction,
  TagActionFromJSON,
  TagActionToJSON,
  TagActionRequest,
  TagActionRequestFromJSON,
  TagActionRequestToJSON,
  Thread,
  ThreadFromJSON,
  ThreadToJSON,
  ThreadActionResult,
  ThreadActionResultFromJSON,
  ThreadActionResultToJSON,
  ThreadBulkActionsRequest,
  ThreadBulkActionsRequestFromJSON,
  ThreadBulkActionsRequestToJSON,
  ThreadBulkTagsRequest,
  ThreadBulkTagsRequestFromJSON,
  ThreadBulkTagsRequestToJSON,
  ThreadTagRead,
  ThreadTagReadFromJSON,
  ThreadTagReadToJSON,
  ThreadTagWrite,
  ThreadTagWriteFromJSON,
  ThreadTagWriteToJSON,
  ThreadTagWriteRequest,
  ThreadTagWriteRequestFromJSON,
  ThreadTagWriteRequestToJSON,
  UnseenThreadCounts,
  UnseenThreadCountsFromJSON,
  UnseenThreadCountsToJSON,
  UserModelPreferences,
  UserModelPreferencesFromJSON,
  UserModelPreferencesToJSON,
  UserThreadTag,
  UserThreadTagFromJSON,
  UserThreadTagToJSON,
  UserThreadTagRequest,
  UserThreadTagRequestFromJSON,
  UserThreadTagRequestToJSON,
} from "../models";

export interface UsersBreedingPairingByRetrieveRequest {
  sex: UsersBreedingPairingByRetrieveSexEnum;
  userId: string;
  ordering?: UsersBreedingPairingByRetrieveOrderingEnum;
  page?: number;
  pageSize?: number;
  search?: string;
}

export interface UsersBreedingPairingCreateRequest {
  userId: string;
  /** @deprecated Use request instead. **/
  breedingPairingDetailsRequest?: BreedingPairingDetailsRequest;
  request: BreedingPairingDetailsRequest;
}

export interface UsersBreedingPairingCreateOffspringGroupCreateRequest {
  id: number;
  userId: string;
  /** @deprecated Use request instead. **/
  breedingPairingDetailsRequest?: BreedingPairingDetailsRequest;
  request: BreedingPairingDetailsRequest;
}

export interface UsersBreedingPairingDestroyRequest {
  id: number;
  userId: string;
}

export interface UsersBreedingPairingListRequest {
  by: UsersBreedingPairingListByEnum;
  userId: string;
  categoryShortName?: string;
  ordering?: UsersBreedingPairingListOrderingEnum;
  page?: number;
  pageSize?: number;
  search?: string;
  season?: string;
  status?: UsersBreedingPairingListStatusEnum;
}

export interface UsersBreedingPairingOutcomesRetrieveRequest {
  userId: string;
}

export interface UsersBreedingPairingPartialUpdateRequest {
  id: number;
  userId: string;
  /** @deprecated Use request instead. **/
  patchedBreedingPairingDetailsRequest?: PatchedBreedingPairingDetailsRequest;
  request?: PatchedBreedingPairingDetailsRequest;
}

export interface UsersBreedingPairingRetrieveRequest {
  id: number;
  userId: string;
}

export interface UsersBreedingPairingUpdateRequest {
  id: number;
  userId: string;
  /** @deprecated Use request instead. **/
  breedingPairingDetailsRequest?: BreedingPairingDetailsRequest;
  request: BreedingPairingDetailsRequest;
}

export interface UsersCollectionListRequest {
  userId: number;
  active?: UsersCollectionListActiveEnum;
  animalId?: string;
  animalIdIn?: Array<string>;
  breedingPairingPrimaryAnimalPk?: string;
  breedingTagSeason?: string;
  category?: string;
  clutchId?: string;
  clutchNumber?: Array<string>;
  damId?: Array<string>;
  isGravid?: string;
  maturity?: UsersCollectionListMaturityEnum;
  ordering?: UsersCollectionListOrderingEnum;
  page?: number;
  pageSize?: number;
  petOnly?: string;
  preyFood?: UsersCollectionListPreyFoodEnum;
  preyStatus?: UsersCollectionListPreyStatusEnum;
  priceMax?: string;
  priceMin?: string;
  priceCurrency?: UsersCollectionListPriceCurrencyEnum;
  search?: Array<string>;
  sex?: Array<UsersCollectionListSexEnum>;
  sireId?: Array<string>;
  state?: Array<UsersCollectionListStateEnum>;
  status?: UsersCollectionListStatusEnum;
  tagsIn?: number;
  tagsNotIn?: number;
  traitCountMax?: number;
  traitCountMin?: number;
  traitsIn?: Array<string>;
  traitsNotIn?: Array<string>;
  transferred?: UsersCollectionListTransferredEnum;
  visibility?: UsersCollectionListVisibilityEnum;
  weightMax?: number | null;
  weightMin?: number | null;
}

export interface UsersMessageThreadsActionsCreateRequest {
  threadId: number;
  userId: string;
  /** @deprecated Use request instead. **/
  messangerActionProxyRequest?: MessangerActionProxyRequest;
  request?: MessangerActionProxyRequest;
}

export interface UsersMessageThreadsActionsListRequest {
  threadId: number;
  userId: string;
}

export interface UsersMessageThreadsActionsMarkSeenPartialUpdateRequest {
  threadId: number;
  userId: string;
}

export interface UsersMessageThreadsBulkActionsCreateRequest {
  userId: string;
  /** @deprecated Use request instead. **/
  threadBulkActionsRequest?: ThreadBulkActionsRequest;
  request: ThreadBulkActionsRequest;
}

export interface UsersMessageThreadsBulkAddTagsToThreadCreateRequest {
  userId: string;
  /** @deprecated Use request instead. **/
  threadBulkTagsRequest?: ThreadBulkTagsRequest;
  request: ThreadBulkTagsRequest;
}

export interface UsersMessageThreadsBulkRemoveTagsFromThreadsCreateRequest {
  userId: string;
  /** @deprecated Use request instead. **/
  threadBulkTagsRequest?: ThreadBulkTagsRequest;
  request: ThreadBulkTagsRequest;
}

export interface UsersMessageThreadsInquiredAnimalsListRequest {
  userId: string;
}

export interface UsersMessageThreadsListRequest {
  userId: string;
  inquiredAnimal?: string;
  messageCollectionType?: UsersMessageThreadsListMessageCollectionTypeEnum;
  messageType?: UsersMessageThreadsListMessageTypeEnum;
  needsReply?: string;
  offers?: string;
  otherParty?: string;
  page?: number;
  pageSize?: number;
  search?: string;
  tagId?: Array<number>;
  tagName?: Array<string>;
  unseen?: string;
}

export interface UsersMessageThreadsMessagesCreateRequest {
  threadId: number;
  userId: string;
  /** @deprecated Use request instead. **/
  newMessageRequest?: NewMessageRequest;
  request?: NewMessageRequest;
}

export interface UsersMessageThreadsMessagesListRequest {
  threadId: number;
  userId: string;
  page?: number;
  pageSize?: number;
}

export interface UsersMessageThreadsOtherPartiesListRequest {
  userId: string;
}

export interface UsersMessageThreadsRetrieveRequest {
  id: number;
  userId: string;
}

export interface UsersMessageThreadsTagsCreateRequest {
  threadId: number;
  userId: string;
  /** @deprecated Use request instead. **/
  threadTagWriteRequest?: ThreadTagWriteRequest;
  request?: ThreadTagWriteRequest;
}

export interface UsersMessageThreadsTagsDestroyRequest {
  id: number;
  threadId: number;
  userId: string;
}

export interface UsersMessageThreadsTagsListRequest {
  threadId: number;
  userId: string;
}

export interface UsersMessageThreadsTagsUpdateThreadUpdateRequest {
  threadId: number;
  userId: string;
  /** @deprecated Use request instead. **/
  tagActionRequest?: TagActionRequest;
  request?: TagActionRequest;
}

export interface UsersMessageThreadsUnseenThreadCountsRetrieveRequest {
  userId: string;
}

export interface UsersOffspringGroupsListRequest {
  userId: string;
  birthDate?: UsersOffspringGroupsListBirthDateEnum;
  category?: string;
  child?: string;
  clutchEggCount?: string;
  clutchEggCountBad?: string;
  clutchEggCountGood?: string;
  clutchEstimatedHatchDate?: UsersOffspringGroupsListClutchEstimatedHatchDateEnum;
  dam?: string;
  damId?: string;
  groupId?: string;
  offspringGroupId?: string;
  ordering?: UsersOffspringGroupsListOrderingEnum;
  page?: number;
  pageSize?: number;
  parent?: string;
  pk?: string;
  search?: string;
  season?: string;
  sire?: string;
  sireId?: string;
  status?: UsersOffspringGroupsListStatusEnum;
  traitsIn?: string;
  traitsNotIn?: string;
  visibility?: UsersOffspringGroupsListVisibilityEnum;
}

export interface UsersOffspringGroupsRetrieveRequest {
  id: number;
  userId: string;
}

export interface UsersPreferencesPartialUpdateRequest {
  /** @deprecated Use request instead. **/
  patchedUserModelPreferencesRequest?: PatchedUserModelPreferencesRequest;
  request?: PatchedUserModelPreferencesRequest;
}

export interface UsersThreadTagsCreateRequest {
  userId: string;
  /** @deprecated Use request instead. **/
  userThreadTagRequest?: UserThreadTagRequest;
  request: UserThreadTagRequest;
}

export interface UsersThreadTagsDestroyRequest {
  id: number;
  userId: string;
}

export interface UsersThreadTagsListRequest {
  userId: string;
}

export interface UsersThreadTagsPartialUpdateRequest {
  id: number;
  userId: string;
  /** @deprecated Use request instead. **/
  patchedUserThreadTagRequest?: PatchedUserThreadTagRequest;
  request?: PatchedUserThreadTagRequest;
}

export interface UsersThreadTagsRetrieveRequest {
  id: number;
  userId: string;
}

export interface UsersThreadTagsUpdateRequest {
  id: number;
  userId: string;
  /** @deprecated Use request instead. **/
  userThreadTagRequest?: UserThreadTagRequest;
  request: UserThreadTagRequest;
}

/**
 *
 */
export class UsersApi extends runtime.BaseAPI {
  /**
   */
  async usersAuctionConsentCreateRaw(initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<AnonymousUser>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
      headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
    }
    const response = await this.request(
      {
        path: `/api/v1/users/auction_consent/`,
        method: "POST",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => AnonymousUserFromJSON(jsonValue));
  }

  /**
   */
  async usersAuctionConsentCreate(initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<AnonymousUser> {
    const response = await this.usersAuctionConsentCreateRaw(initOverrides);
    return await response.value();
  }

  /**
   * This list view is used for mobile view, where it is grouped by primary animal, i.e. if one animal is in two pairings this view will return one records with pairings list for each record. It\'s different from desktop view, where each pairing has one record and is not grouped by primary animal. url for this view is like this: `api/v1/breeding_pairing/by/{female|male}/?...
   */
  async usersBreedingPairingByRetrieveRaw(
    requestParameters: UsersBreedingPairingByRetrieveRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<runtime.ApiResponse<BreedingPairingListByPrimaryAnimal>> {
    if (requestParameters.sex === null || requestParameters.sex === undefined) {
      throw new runtime.RequiredError("sex", "Required parameter requestParameters.sex was null or undefined when calling usersBreedingPairingByRetrieve.");
    }

    if (requestParameters.userId === null || requestParameters.userId === undefined) {
      throw new runtime.RequiredError("userId", "Required parameter requestParameters.userId was null or undefined when calling usersBreedingPairingByRetrieve.");
    }

    const queryParameters: any = {};

    if (requestParameters.ordering !== undefined) {
      queryParameters["ordering"] = requestParameters.ordering;
    }

    if (requestParameters.page !== undefined) {
      queryParameters["page"] = requestParameters.page;
    }

    if (requestParameters.pageSize !== undefined) {
      queryParameters["page_size"] = requestParameters.pageSize;
    }

    if (requestParameters.search !== undefined) {
      queryParameters["search"] = requestParameters.search;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
      headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
    }
    const response = await this.request(
      {
        path: `/api/v1/users/{user_id}/breeding_pairing/by/{sex}/`
          .replace(`{${"sex"}}`, encodeURIComponent(String(requestParameters.sex)))
          .replace(`{${"user_id"}}`, encodeURIComponent(String(requestParameters.userId))),
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => BreedingPairingListByPrimaryAnimalFromJSON(jsonValue));
  }

  /**
   * This list view is used for mobile view, where it is grouped by primary animal, i.e. if one animal is in two pairings this view will return one records with pairings list for each record. It\'s different from desktop view, where each pairing has one record and is not grouped by primary animal. url for this view is like this: `api/v1/breeding_pairing/by/{female|male}/?...
   */
  async usersBreedingPairingByRetrieve(
    requestParameters: UsersBreedingPairingByRetrieveRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<BreedingPairingListByPrimaryAnimal> {
    const response = await this.usersBreedingPairingByRetrieveRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   */
  async usersBreedingPairingCreateRaw(
    requestParameters: UsersBreedingPairingCreateRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<runtime.ApiResponse<BreedingPairingDetails>> {
    if (requestParameters.userId === null || requestParameters.userId === undefined) {
      throw new runtime.RequiredError("userId", "Required parameter requestParameters.userId was null or undefined when calling usersBreedingPairingCreate.");
    }

    if (
      (requestParameters.request || requestParameters.breedingPairingDetailsRequest) === null ||
      (requestParameters.request || requestParameters.breedingPairingDetailsRequest) === undefined
    ) {
      throw new runtime.RequiredError(
        "breedingPairingDetailsRequest",
        "Required parameter (requestParameters.request || requestParameters.breedingPairingDetailsRequest) was null or undefined when calling usersBreedingPairingCreate."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
      headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
    }
    const response = await this.request(
      {
        path: `/api/v1/users/{user_id}/breeding_pairing/`.replace(`{${"user_id"}}`, encodeURIComponent(String(requestParameters.userId))),
        method: "POST",
        headers: headerParameters,
        query: queryParameters,
        body: BreedingPairingDetailsRequestToJSON(requestParameters.request || requestParameters.breedingPairingDetailsRequest),
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => BreedingPairingDetailsFromJSON(jsonValue));
  }

  /**
   */
  async usersBreedingPairingCreate(
    requestParameters: UsersBreedingPairingCreateRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<BreedingPairingDetails> {
    const response = await this.usersBreedingPairingCreateRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   */
  async usersBreedingPairingCreateOffspringGroupCreateRaw(
    requestParameters: UsersBreedingPairingCreateOffspringGroupCreateRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<runtime.ApiResponse<BreedingPairingDetails>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError("id", "Required parameter requestParameters.id was null or undefined when calling usersBreedingPairingCreateOffspringGroupCreate.");
    }

    if (requestParameters.userId === null || requestParameters.userId === undefined) {
      throw new runtime.RequiredError("userId", "Required parameter requestParameters.userId was null or undefined when calling usersBreedingPairingCreateOffspringGroupCreate.");
    }

    if (
      (requestParameters.request || requestParameters.breedingPairingDetailsRequest) === null ||
      (requestParameters.request || requestParameters.breedingPairingDetailsRequest) === undefined
    ) {
      throw new runtime.RequiredError(
        "breedingPairingDetailsRequest",
        "Required parameter (requestParameters.request || requestParameters.breedingPairingDetailsRequest) was null or undefined when calling usersBreedingPairingCreateOffspringGroupCreate."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
      headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
    }
    const response = await this.request(
      {
        path: `/api/v1/users/{user_id}/breeding_pairing/{id}/create_offspring_group/`
          .replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id)))
          .replace(`{${"user_id"}}`, encodeURIComponent(String(requestParameters.userId))),
        method: "POST",
        headers: headerParameters,
        query: queryParameters,
        body: BreedingPairingDetailsRequestToJSON(requestParameters.request || requestParameters.breedingPairingDetailsRequest),
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => BreedingPairingDetailsFromJSON(jsonValue));
  }

  /**
   */
  async usersBreedingPairingCreateOffspringGroupCreate(
    requestParameters: UsersBreedingPairingCreateOffspringGroupCreateRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<BreedingPairingDetails> {
    const response = await this.usersBreedingPairingCreateOffspringGroupCreateRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   */
  async usersBreedingPairingDestroyRaw(
    requestParameters: UsersBreedingPairingDestroyRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<runtime.ApiResponse<void>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError("id", "Required parameter requestParameters.id was null or undefined when calling usersBreedingPairingDestroy.");
    }

    if (requestParameters.userId === null || requestParameters.userId === undefined) {
      throw new runtime.RequiredError("userId", "Required parameter requestParameters.userId was null or undefined when calling usersBreedingPairingDestroy.");
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
      headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
    }
    const response = await this.request(
      {
        path: `/api/v1/users/{user_id}/breeding_pairing/{id}/`
          .replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id)))
          .replace(`{${"user_id"}}`, encodeURIComponent(String(requestParameters.userId))),
        method: "DELETE",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    );

    return new runtime.VoidApiResponse(response);
  }

  /**
   */
  async usersBreedingPairingDestroy(requestParameters: UsersBreedingPairingDestroyRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<void> {
    await this.usersBreedingPairingDestroyRaw(requestParameters, initOverrides);
  }

  /**
   * This list view is used for desktop view, where it is not grouped by primary animal, i.e. if one animal is in two pairings this view will return two records. It\'s different for mobile views, where it is grouped by primary animal. url for this view is like this: `api/v1/breeding_pairing/?by={female|male}&...
   */
  async usersBreedingPairingListRaw(
    requestParameters: UsersBreedingPairingListRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<runtime.ApiResponse<PaginatedBreedingPairingListList>> {
    if (requestParameters.by === null || requestParameters.by === undefined) {
      throw new runtime.RequiredError("by", "Required parameter requestParameters.by was null or undefined when calling usersBreedingPairingList.");
    }

    if (requestParameters.userId === null || requestParameters.userId === undefined) {
      throw new runtime.RequiredError("userId", "Required parameter requestParameters.userId was null or undefined when calling usersBreedingPairingList.");
    }

    const queryParameters: any = {};

    if (requestParameters.by !== undefined) {
      queryParameters["by"] = requestParameters.by;
    }

    if (requestParameters.categoryShortName !== undefined) {
      queryParameters["category_short_name"] = requestParameters.categoryShortName;
    }

    if (requestParameters.ordering !== undefined) {
      queryParameters["ordering"] = requestParameters.ordering;
    }

    if (requestParameters.page !== undefined) {
      queryParameters["page"] = requestParameters.page;
    }

    if (requestParameters.pageSize !== undefined) {
      queryParameters["page_size"] = requestParameters.pageSize;
    }

    if (requestParameters.search !== undefined) {
      queryParameters["search"] = requestParameters.search;
    }

    if (requestParameters.season !== undefined) {
      queryParameters["season"] = requestParameters.season;
    }

    if (requestParameters.status !== undefined) {
      queryParameters["status"] = requestParameters.status;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
      headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
    }
    const response = await this.request(
      {
        path: `/api/v1/users/{user_id}/breeding_pairing/`.replace(`{${"user_id"}}`, encodeURIComponent(String(requestParameters.userId))),
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => PaginatedBreedingPairingListListFromJSON(jsonValue));
  }

  /**
   * This list view is used for desktop view, where it is not grouped by primary animal, i.e. if one animal is in two pairings this view will return two records. It\'s different for mobile views, where it is grouped by primary animal. url for this view is like this: `api/v1/breeding_pairing/?by={female|male}&...
   */
  async usersBreedingPairingList(
    requestParameters: UsersBreedingPairingListRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<PaginatedBreedingPairingListList> {
    const response = await this.usersBreedingPairingListRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   */
  async usersBreedingPairingOutcomesRetrieveRaw(
    requestParameters: UsersBreedingPairingOutcomesRetrieveRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<runtime.ApiResponse<BreedingPairingDetails>> {
    if (requestParameters.userId === null || requestParameters.userId === undefined) {
      throw new runtime.RequiredError("userId", "Required parameter requestParameters.userId was null or undefined when calling usersBreedingPairingOutcomesRetrieve.");
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
      headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
    }
    const response = await this.request(
      {
        path: `/api/v1/users/{user_id}/breeding_pairing/outcomes/`.replace(`{${"user_id"}}`, encodeURIComponent(String(requestParameters.userId))),
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => BreedingPairingDetailsFromJSON(jsonValue));
  }

  /**
   */
  async usersBreedingPairingOutcomesRetrieve(
    requestParameters: UsersBreedingPairingOutcomesRetrieveRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<BreedingPairingDetails> {
    const response = await this.usersBreedingPairingOutcomesRetrieveRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   */
  async usersBreedingPairingPartialUpdateRaw(
    requestParameters: UsersBreedingPairingPartialUpdateRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<runtime.ApiResponse<BreedingPairingDetails>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError("id", "Required parameter requestParameters.id was null or undefined when calling usersBreedingPairingPartialUpdate.");
    }

    if (requestParameters.userId === null || requestParameters.userId === undefined) {
      throw new runtime.RequiredError("userId", "Required parameter requestParameters.userId was null or undefined when calling usersBreedingPairingPartialUpdate.");
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
      headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
    }
    const response = await this.request(
      {
        path: `/api/v1/users/{user_id}/breeding_pairing/{id}/`
          .replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id)))
          .replace(`{${"user_id"}}`, encodeURIComponent(String(requestParameters.userId))),
        method: "PATCH",
        headers: headerParameters,
        query: queryParameters,
        body: PatchedBreedingPairingDetailsRequestToJSON(requestParameters.request || requestParameters.patchedBreedingPairingDetailsRequest),
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => BreedingPairingDetailsFromJSON(jsonValue));
  }

  /**
   */
  async usersBreedingPairingPartialUpdate(
    requestParameters: UsersBreedingPairingPartialUpdateRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<BreedingPairingDetails> {
    const response = await this.usersBreedingPairingPartialUpdateRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   */
  async usersBreedingPairingRetrieveRaw(
    requestParameters: UsersBreedingPairingRetrieveRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<runtime.ApiResponse<BreedingPairingDetails>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError("id", "Required parameter requestParameters.id was null or undefined when calling usersBreedingPairingRetrieve.");
    }

    if (requestParameters.userId === null || requestParameters.userId === undefined) {
      throw new runtime.RequiredError("userId", "Required parameter requestParameters.userId was null or undefined when calling usersBreedingPairingRetrieve.");
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
      headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
    }
    const response = await this.request(
      {
        path: `/api/v1/users/{user_id}/breeding_pairing/{id}/`
          .replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id)))
          .replace(`{${"user_id"}}`, encodeURIComponent(String(requestParameters.userId))),
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => BreedingPairingDetailsFromJSON(jsonValue));
  }

  /**
   */
  async usersBreedingPairingRetrieve(
    requestParameters: UsersBreedingPairingRetrieveRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<BreedingPairingDetails> {
    const response = await this.usersBreedingPairingRetrieveRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   */
  async usersBreedingPairingUpdateRaw(
    requestParameters: UsersBreedingPairingUpdateRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<runtime.ApiResponse<BreedingPairingDetails>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError("id", "Required parameter requestParameters.id was null or undefined when calling usersBreedingPairingUpdate.");
    }

    if (requestParameters.userId === null || requestParameters.userId === undefined) {
      throw new runtime.RequiredError("userId", "Required parameter requestParameters.userId was null or undefined when calling usersBreedingPairingUpdate.");
    }

    if (
      (requestParameters.request || requestParameters.breedingPairingDetailsRequest) === null ||
      (requestParameters.request || requestParameters.breedingPairingDetailsRequest) === undefined
    ) {
      throw new runtime.RequiredError(
        "breedingPairingDetailsRequest",
        "Required parameter (requestParameters.request || requestParameters.breedingPairingDetailsRequest) was null or undefined when calling usersBreedingPairingUpdate."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
      headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
    }
    const response = await this.request(
      {
        path: `/api/v1/users/{user_id}/breeding_pairing/{id}/`
          .replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id)))
          .replace(`{${"user_id"}}`, encodeURIComponent(String(requestParameters.userId))),
        method: "PUT",
        headers: headerParameters,
        query: queryParameters,
        body: BreedingPairingDetailsRequestToJSON(requestParameters.request || requestParameters.breedingPairingDetailsRequest),
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => BreedingPairingDetailsFromJSON(jsonValue));
  }

  /**
   */
  async usersBreedingPairingUpdate(
    requestParameters: UsersBreedingPairingUpdateRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<BreedingPairingDetails> {
    const response = await this.usersBreedingPairingUpdateRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   */
  async usersCollectionListRaw(
    requestParameters: UsersCollectionListRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<runtime.ApiResponse<PaginatedPublicAnimalList>> {
    if (requestParameters.userId === null || requestParameters.userId === undefined) {
      throw new runtime.RequiredError("userId", "Required parameter requestParameters.userId was null or undefined when calling usersCollectionList.");
    }

    const queryParameters: any = {};

    if (requestParameters.active !== undefined) {
      queryParameters["active"] = requestParameters.active;
    }

    if (requestParameters.animalId !== undefined) {
      queryParameters["animal_id"] = requestParameters.animalId;
    }

    if (requestParameters.animalIdIn) {
      queryParameters["animal_id_in"] = requestParameters.animalIdIn;
    }

    if (requestParameters.breedingPairingPrimaryAnimalPk !== undefined) {
      queryParameters["breeding_pairing_primary_animal_pk"] = requestParameters.breedingPairingPrimaryAnimalPk;
    }

    if (requestParameters.breedingTagSeason !== undefined) {
      queryParameters["breeding_tag_season"] = requestParameters.breedingTagSeason;
    }

    if (requestParameters.category !== undefined) {
      queryParameters["category"] = requestParameters.category;
    }

    if (requestParameters.clutchId !== undefined) {
      queryParameters["clutch_id"] = requestParameters.clutchId;
    }

    if (requestParameters.clutchNumber) {
      queryParameters["clutch_number"] = requestParameters.clutchNumber;
    }

    if (requestParameters.damId) {
      queryParameters["dam_id"] = requestParameters.damId;
    }

    if (requestParameters.isGravid !== undefined) {
      queryParameters["is_gravid"] = requestParameters.isGravid;
    }

    if (requestParameters.maturity !== undefined) {
      queryParameters["maturity"] = requestParameters.maturity;
    }

    if (requestParameters.ordering !== undefined) {
      queryParameters["ordering"] = requestParameters.ordering;
    }

    if (requestParameters.page !== undefined) {
      queryParameters["page"] = requestParameters.page;
    }

    if (requestParameters.pageSize !== undefined) {
      queryParameters["page_size"] = requestParameters.pageSize;
    }

    if (requestParameters.petOnly !== undefined) {
      queryParameters["pet_only"] = requestParameters.petOnly;
    }

    if (requestParameters.preyFood !== undefined) {
      queryParameters["prey_food"] = requestParameters.preyFood;
    }

    if (requestParameters.preyStatus !== undefined) {
      queryParameters["prey_status"] = requestParameters.preyStatus;
    }

    if (requestParameters.priceMax !== undefined) {
      queryParameters["price__max"] = requestParameters.priceMax;
    }

    if (requestParameters.priceMin !== undefined) {
      queryParameters["price__min"] = requestParameters.priceMin;
    }

    if (requestParameters.priceCurrency !== undefined) {
      queryParameters["price_currency"] = requestParameters.priceCurrency;
    }

    if (requestParameters.search) {
      queryParameters["search"] = requestParameters.search;
    }

    if (requestParameters.sex) {
      queryParameters["sex"] = requestParameters.sex;
    }

    if (requestParameters.sireId) {
      queryParameters["sire_id"] = requestParameters.sireId;
    }

    if (requestParameters.state) {
      queryParameters["state"] = requestParameters.state;
    }

    if (requestParameters.status !== undefined) {
      queryParameters["status"] = requestParameters.status;
    }

    if (requestParameters.tagsIn !== undefined) {
      queryParameters["tags__in"] = requestParameters.tagsIn;
    }

    if (requestParameters.tagsNotIn !== undefined) {
      queryParameters["tags__not__in"] = requestParameters.tagsNotIn;
    }

    if (requestParameters.traitCountMax !== undefined) {
      queryParameters["trait_count__max"] = requestParameters.traitCountMax;
    }

    if (requestParameters.traitCountMin !== undefined) {
      queryParameters["trait_count__min"] = requestParameters.traitCountMin;
    }

    if (requestParameters.traitsIn) {
      queryParameters["traits__in"] = requestParameters.traitsIn;
    }

    if (requestParameters.traitsNotIn) {
      queryParameters["traits__not__in"] = requestParameters.traitsNotIn;
    }

    if (requestParameters.transferred !== undefined) {
      queryParameters["transferred"] = requestParameters.transferred;
    }

    if (requestParameters.visibility !== undefined) {
      queryParameters["visibility"] = requestParameters.visibility;
    }

    if (requestParameters.weightMax !== undefined) {
      queryParameters["weight__max"] = requestParameters.weightMax;
    }

    if (requestParameters.weightMin !== undefined) {
      queryParameters["weight__min"] = requestParameters.weightMin;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
      headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
    }
    const response = await this.request(
      {
        path: `/api/v1/users/{user_id}/collection/`.replace(`{${"user_id"}}`, encodeURIComponent(String(requestParameters.userId))),
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => PaginatedPublicAnimalListFromJSON(jsonValue));
  }

  /**
   */
  async usersCollectionList(requestParameters: UsersCollectionListRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<PaginatedPublicAnimalList> {
    const response = await this.usersCollectionListRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * This endpoint returns information pertaining to the user when the user is logged in.  When the user is not logged in, it returns \"successfully\" with 202. The FE uses the response to determine that it is not logged in, doing so in a way that is decoupled from the HTML produced by Django.
   */
  async usersMeRetrieveRaw(initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<AbstractUser>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
      headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
    }
    const response = await this.request(
      {
        path: `/api/v1/users/me/`,
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => AbstractUserFromJSON(jsonValue));
  }

  /**
   * This endpoint returns information pertaining to the user when the user is logged in.  When the user is not logged in, it returns \"successfully\" with 202. The FE uses the response to determine that it is not logged in, doing so in a way that is decoupled from the HTML produced by Django.
   */
  async usersMeRetrieve(initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<AbstractUser> {
    const response = await this.usersMeRetrieveRaw(initOverrides);
    return await response.value();
  }

  /**
   * Messanger actions. GET method - returns available actions for the given thread. POST method - performs actual action.
   */
  async usersMessageThreadsActionsCreateRaw(
    requestParameters: UsersMessageThreadsActionsCreateRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<runtime.ApiResponse<ActionResponse>> {
    if (requestParameters.threadId === null || requestParameters.threadId === undefined) {
      throw new runtime.RequiredError("threadId", "Required parameter requestParameters.threadId was null or undefined when calling usersMessageThreadsActionsCreate.");
    }

    if (requestParameters.userId === null || requestParameters.userId === undefined) {
      throw new runtime.RequiredError("userId", "Required parameter requestParameters.userId was null or undefined when calling usersMessageThreadsActionsCreate.");
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
      headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
    }
    const response = await this.request(
      {
        path: `/api/v1/users/{user_id}/message_threads/{thread_id}/actions/`
          .replace(`{${"thread_id"}}`, encodeURIComponent(String(requestParameters.threadId)))
          .replace(`{${"user_id"}}`, encodeURIComponent(String(requestParameters.userId))),
        method: "POST",
        headers: headerParameters,
        query: queryParameters,
        body: MessangerActionProxyRequestToJSON(requestParameters.request || requestParameters.messangerActionProxyRequest),
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => ActionResponseFromJSON(jsonValue));
  }

  /**
   * Messanger actions. GET method - returns available actions for the given thread. POST method - performs actual action.
   */
  async usersMessageThreadsActionsCreate(
    requestParameters: UsersMessageThreadsActionsCreateRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<ActionResponse> {
    const response = await this.usersMessageThreadsActionsCreateRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Messanger actions. GET method - returns available actions for the given thread. POST method - performs actual action.
   */
  async usersMessageThreadsActionsListRaw(
    requestParameters: UsersMessageThreadsActionsListRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<runtime.ApiResponse<Array<AvailableAction>>> {
    if (requestParameters.threadId === null || requestParameters.threadId === undefined) {
      throw new runtime.RequiredError("threadId", "Required parameter requestParameters.threadId was null or undefined when calling usersMessageThreadsActionsList.");
    }

    if (requestParameters.userId === null || requestParameters.userId === undefined) {
      throw new runtime.RequiredError("userId", "Required parameter requestParameters.userId was null or undefined when calling usersMessageThreadsActionsList.");
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
      headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
    }
    const response = await this.request(
      {
        path: `/api/v1/users/{user_id}/message_threads/{thread_id}/actions/`
          .replace(`{${"thread_id"}}`, encodeURIComponent(String(requestParameters.threadId)))
          .replace(`{${"user_id"}}`, encodeURIComponent(String(requestParameters.userId))),
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(AvailableActionFromJSON));
  }

  /**
   * Messanger actions. GET method - returns available actions for the given thread. POST method - performs actual action.
   */
  async usersMessageThreadsActionsList(
    requestParameters: UsersMessageThreadsActionsListRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<Array<AvailableAction>> {
    const response = await this.usersMessageThreadsActionsListRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Messanger actions. GET method - returns available actions for the given thread. POST method - performs actual action.
   */
  async usersMessageThreadsActionsMarkSeenPartialUpdateRaw(
    requestParameters: UsersMessageThreadsActionsMarkSeenPartialUpdateRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<runtime.ApiResponse<MarkSeenResponse>> {
    if (requestParameters.threadId === null || requestParameters.threadId === undefined) {
      throw new runtime.RequiredError(
        "threadId",
        "Required parameter requestParameters.threadId was null or undefined when calling usersMessageThreadsActionsMarkSeenPartialUpdate."
      );
    }

    if (requestParameters.userId === null || requestParameters.userId === undefined) {
      throw new runtime.RequiredError("userId", "Required parameter requestParameters.userId was null or undefined when calling usersMessageThreadsActionsMarkSeenPartialUpdate.");
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
      headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
    }
    const response = await this.request(
      {
        path: `/api/v1/users/{user_id}/message_threads/{thread_id}/actions/mark_seen/`
          .replace(`{${"thread_id"}}`, encodeURIComponent(String(requestParameters.threadId)))
          .replace(`{${"user_id"}}`, encodeURIComponent(String(requestParameters.userId))),
        method: "PATCH",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => MarkSeenResponseFromJSON(jsonValue));
  }

  /**
   * Messanger actions. GET method - returns available actions for the given thread. POST method - performs actual action.
   */
  async usersMessageThreadsActionsMarkSeenPartialUpdate(
    requestParameters: UsersMessageThreadsActionsMarkSeenPartialUpdateRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<MarkSeenResponse> {
    const response = await this.usersMessageThreadsActionsMarkSeenPartialUpdateRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * This endpoint performs bulk actions on the given threads.
   */
  async usersMessageThreadsBulkActionsCreateRaw(
    requestParameters: UsersMessageThreadsBulkActionsCreateRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<runtime.ApiResponse<Array<ThreadActionResult>>> {
    if (requestParameters.userId === null || requestParameters.userId === undefined) {
      throw new runtime.RequiredError("userId", "Required parameter requestParameters.userId was null or undefined when calling usersMessageThreadsBulkActionsCreate.");
    }

    if (
      (requestParameters.request || requestParameters.threadBulkActionsRequest) === null ||
      (requestParameters.request || requestParameters.threadBulkActionsRequest) === undefined
    ) {
      throw new runtime.RequiredError(
        "threadBulkActionsRequest",
        "Required parameter (requestParameters.request || requestParameters.threadBulkActionsRequest) was null or undefined when calling usersMessageThreadsBulkActionsCreate."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
      headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
    }
    const response = await this.request(
      {
        path: `/api/v1/users/{user_id}/message_threads/bulk_actions/`.replace(`{${"user_id"}}`, encodeURIComponent(String(requestParameters.userId))),
        method: "POST",
        headers: headerParameters,
        query: queryParameters,
        body: ThreadBulkActionsRequestToJSON(requestParameters.request || requestParameters.threadBulkActionsRequest),
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ThreadActionResultFromJSON));
  }

  /**
   * This endpoint performs bulk actions on the given threads.
   */
  async usersMessageThreadsBulkActionsCreate(
    requestParameters: UsersMessageThreadsBulkActionsCreateRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<Array<ThreadActionResult>> {
    const response = await this.usersMessageThreadsBulkActionsCreateRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * This endpoint adds tags to the given threads.
   */
  async usersMessageThreadsBulkAddTagsToThreadCreateRaw(
    requestParameters: UsersMessageThreadsBulkAddTagsToThreadCreateRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<runtime.ApiResponse<Array<ThreadActionResult>>> {
    if (requestParameters.userId === null || requestParameters.userId === undefined) {
      throw new runtime.RequiredError("userId", "Required parameter requestParameters.userId was null or undefined when calling usersMessageThreadsBulkAddTagsToThreadCreate.");
    }

    if ((requestParameters.request || requestParameters.threadBulkTagsRequest) === null || (requestParameters.request || requestParameters.threadBulkTagsRequest) === undefined) {
      throw new runtime.RequiredError(
        "threadBulkTagsRequest",
        "Required parameter (requestParameters.request || requestParameters.threadBulkTagsRequest) was null or undefined when calling usersMessageThreadsBulkAddTagsToThreadCreate."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
      headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
    }
    const response = await this.request(
      {
        path: `/api/v1/users/{user_id}/message_threads/bulk_add_tags_to_thread/`.replace(`{${"user_id"}}`, encodeURIComponent(String(requestParameters.userId))),
        method: "POST",
        headers: headerParameters,
        query: queryParameters,
        body: ThreadBulkTagsRequestToJSON(requestParameters.request || requestParameters.threadBulkTagsRequest),
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ThreadActionResultFromJSON));
  }

  /**
   * This endpoint adds tags to the given threads.
   */
  async usersMessageThreadsBulkAddTagsToThreadCreate(
    requestParameters: UsersMessageThreadsBulkAddTagsToThreadCreateRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<Array<ThreadActionResult>> {
    const response = await this.usersMessageThreadsBulkAddTagsToThreadCreateRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * This endpoint removes tags from the given threads.
   */
  async usersMessageThreadsBulkRemoveTagsFromThreadsCreateRaw(
    requestParameters: UsersMessageThreadsBulkRemoveTagsFromThreadsCreateRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<runtime.ApiResponse<Array<ThreadActionResult>>> {
    if (requestParameters.userId === null || requestParameters.userId === undefined) {
      throw new runtime.RequiredError(
        "userId",
        "Required parameter requestParameters.userId was null or undefined when calling usersMessageThreadsBulkRemoveTagsFromThreadsCreate."
      );
    }

    if ((requestParameters.request || requestParameters.threadBulkTagsRequest) === null || (requestParameters.request || requestParameters.threadBulkTagsRequest) === undefined) {
      throw new runtime.RequiredError(
        "threadBulkTagsRequest",
        "Required parameter (requestParameters.request || requestParameters.threadBulkTagsRequest) was null or undefined when calling usersMessageThreadsBulkRemoveTagsFromThreadsCreate."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
      headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
    }
    const response = await this.request(
      {
        path: `/api/v1/users/{user_id}/message_threads/bulk_remove_tags_from_threads/`.replace(`{${"user_id"}}`, encodeURIComponent(String(requestParameters.userId))),
        method: "POST",
        headers: headerParameters,
        query: queryParameters,
        body: ThreadBulkTagsRequestToJSON(requestParameters.request || requestParameters.threadBulkTagsRequest),
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ThreadActionResultFromJSON));
  }

  /**
   * This endpoint removes tags from the given threads.
   */
  async usersMessageThreadsBulkRemoveTagsFromThreadsCreate(
    requestParameters: UsersMessageThreadsBulkRemoveTagsFromThreadsCreateRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<Array<ThreadActionResult>> {
    const response = await this.usersMessageThreadsBulkRemoveTagsFromThreadsCreateRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Returns all inquired animals.
   */
  async usersMessageThreadsInquiredAnimalsListRaw(
    requestParameters: UsersMessageThreadsInquiredAnimalsListRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<runtime.ApiResponse<Array<InquiredAnimal>>> {
    if (requestParameters.userId === null || requestParameters.userId === undefined) {
      throw new runtime.RequiredError("userId", "Required parameter requestParameters.userId was null or undefined when calling usersMessageThreadsInquiredAnimalsList.");
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
      headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
    }
    const response = await this.request(
      {
        path: `/api/v1/users/{user_id}/message_threads/inquired_animals/`.replace(`{${"user_id"}}`, encodeURIComponent(String(requestParameters.userId))),
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(InquiredAnimalFromJSON));
  }

  /**
   * Returns all inquired animals.
   */
  async usersMessageThreadsInquiredAnimalsList(
    requestParameters: UsersMessageThreadsInquiredAnimalsListRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<Array<InquiredAnimal>> {
    const response = await this.usersMessageThreadsInquiredAnimalsListRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * This endpoint may be used for listing message threads for a given user. Its url is `/api/v1/users/{username}/message_threads/` and it accepts only GET method.
   */
  async usersMessageThreadsListRaw(
    requestParameters: UsersMessageThreadsListRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<runtime.ApiResponse<PaginatedThreadList>> {
    if (requestParameters.userId === null || requestParameters.userId === undefined) {
      throw new runtime.RequiredError("userId", "Required parameter requestParameters.userId was null or undefined when calling usersMessageThreadsList.");
    }

    const queryParameters: any = {};

    if (requestParameters.inquiredAnimal !== undefined) {
      queryParameters["inquired_animal"] = requestParameters.inquiredAnimal;
    }

    if (requestParameters.messageCollectionType !== undefined) {
      queryParameters["message_collection_type"] = requestParameters.messageCollectionType;
    }

    if (requestParameters.messageType !== undefined) {
      queryParameters["message_type"] = requestParameters.messageType;
    }

    if (requestParameters.needsReply !== undefined) {
      queryParameters["needs_reply"] = requestParameters.needsReply;
    }

    if (requestParameters.offers !== undefined) {
      queryParameters["offers"] = requestParameters.offers;
    }

    if (requestParameters.otherParty !== undefined) {
      queryParameters["other_party"] = requestParameters.otherParty;
    }

    if (requestParameters.page !== undefined) {
      queryParameters["page"] = requestParameters.page;
    }

    if (requestParameters.pageSize !== undefined) {
      queryParameters["page_size"] = requestParameters.pageSize;
    }

    if (requestParameters.search !== undefined) {
      queryParameters["search"] = requestParameters.search;
    }

    if (requestParameters.tagId) {
      queryParameters["tag_id"] = requestParameters.tagId;
    }

    if (requestParameters.tagName) {
      queryParameters["tag_name"] = requestParameters.tagName;
    }

    if (requestParameters.unseen !== undefined) {
      queryParameters["unseen"] = requestParameters.unseen;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
      headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
    }
    const response = await this.request(
      {
        path: `/api/v1/users/{user_id}/message_threads/`.replace(`{${"user_id"}}`, encodeURIComponent(String(requestParameters.userId))),
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => PaginatedThreadListFromJSON(jsonValue));
  }

  /**
   * This endpoint may be used for listing message threads for a given user. Its url is `/api/v1/users/{username}/message_threads/` and it accepts only GET method.
   */
  async usersMessageThreadsList(requestParameters: UsersMessageThreadsListRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<PaginatedThreadList> {
    const response = await this.usersMessageThreadsListRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * This endpoint may be used for creating and listing messages for the given user and the existing thread. It\'s registered on the following url `/api/v1/users/{user_id}/message_threads/{thead_id}/messages` and it accepts GET and POST methods.
   */
  async usersMessageThreadsMessagesCreateRaw(
    requestParameters: UsersMessageThreadsMessagesCreateRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<runtime.ApiResponse<NewMessageResponse>> {
    if (requestParameters.threadId === null || requestParameters.threadId === undefined) {
      throw new runtime.RequiredError("threadId", "Required parameter requestParameters.threadId was null or undefined when calling usersMessageThreadsMessagesCreate.");
    }

    if (requestParameters.userId === null || requestParameters.userId === undefined) {
      throw new runtime.RequiredError("userId", "Required parameter requestParameters.userId was null or undefined when calling usersMessageThreadsMessagesCreate.");
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
      headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
    }
    const response = await this.request(
      {
        path: `/api/v1/users/{user_id}/message_threads/{thread_id}/messages/`
          .replace(`{${"thread_id"}}`, encodeURIComponent(String(requestParameters.threadId)))
          .replace(`{${"user_id"}}`, encodeURIComponent(String(requestParameters.userId))),
        method: "POST",
        headers: headerParameters,
        query: queryParameters,
        body: NewMessageRequestToJSON(requestParameters.request || requestParameters.newMessageRequest),
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => NewMessageResponseFromJSON(jsonValue));
  }

  /**
   * This endpoint may be used for creating and listing messages for the given user and the existing thread. It\'s registered on the following url `/api/v1/users/{user_id}/message_threads/{thead_id}/messages` and it accepts GET and POST methods.
   */
  async usersMessageThreadsMessagesCreate(
    requestParameters: UsersMessageThreadsMessagesCreateRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<NewMessageResponse> {
    const response = await this.usersMessageThreadsMessagesCreateRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * This endpoint may be used for creating and listing messages for the given user and the existing thread. It\'s registered on the following url `/api/v1/users/{user_id}/message_threads/{thead_id}/messages` and it accepts GET and POST methods.
   */
  async usersMessageThreadsMessagesListRaw(
    requestParameters: UsersMessageThreadsMessagesListRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<runtime.ApiResponse<PaginatedMessageList>> {
    if (requestParameters.threadId === null || requestParameters.threadId === undefined) {
      throw new runtime.RequiredError("threadId", "Required parameter requestParameters.threadId was null or undefined when calling usersMessageThreadsMessagesList.");
    }

    if (requestParameters.userId === null || requestParameters.userId === undefined) {
      throw new runtime.RequiredError("userId", "Required parameter requestParameters.userId was null or undefined when calling usersMessageThreadsMessagesList.");
    }

    const queryParameters: any = {};

    if (requestParameters.page !== undefined) {
      queryParameters["page"] = requestParameters.page;
    }

    if (requestParameters.pageSize !== undefined) {
      queryParameters["page_size"] = requestParameters.pageSize;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
      headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
    }
    const response = await this.request(
      {
        path: `/api/v1/users/{user_id}/message_threads/{thread_id}/messages/`
          .replace(`{${"thread_id"}}`, encodeURIComponent(String(requestParameters.threadId)))
          .replace(`{${"user_id"}}`, encodeURIComponent(String(requestParameters.userId))),
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => PaginatedMessageListFromJSON(jsonValue));
  }

  /**
   * This endpoint may be used for creating and listing messages for the given user and the existing thread. It\'s registered on the following url `/api/v1/users/{user_id}/message_threads/{thead_id}/messages` and it accepts GET and POST methods.
   */
  async usersMessageThreadsMessagesList(
    requestParameters: UsersMessageThreadsMessagesListRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<PaginatedMessageList> {
    const response = await this.usersMessageThreadsMessagesListRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Returns all other users that this user has been in a Thread with.
   */
  async usersMessageThreadsOtherPartiesListRaw(
    requestParameters: UsersMessageThreadsOtherPartiesListRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<runtime.ApiResponse<Array<OtherParty>>> {
    if (requestParameters.userId === null || requestParameters.userId === undefined) {
      throw new runtime.RequiredError("userId", "Required parameter requestParameters.userId was null or undefined when calling usersMessageThreadsOtherPartiesList.");
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
      headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
    }
    const response = await this.request(
      {
        path: `/api/v1/users/{user_id}/message_threads/other_parties/`.replace(`{${"user_id"}}`, encodeURIComponent(String(requestParameters.userId))),
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(OtherPartyFromJSON));
  }

  /**
   * Returns all other users that this user has been in a Thread with.
   */
  async usersMessageThreadsOtherPartiesList(
    requestParameters: UsersMessageThreadsOtherPartiesListRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<Array<OtherParty>> {
    const response = await this.usersMessageThreadsOtherPartiesListRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * This endpoint may be used for listing message threads for a given user. Its url is `/api/v1/users/{username}/message_threads/` and it accepts only GET method.
   */
  async usersMessageThreadsRetrieveRaw(
    requestParameters: UsersMessageThreadsRetrieveRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<runtime.ApiResponse<Thread>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError("id", "Required parameter requestParameters.id was null or undefined when calling usersMessageThreadsRetrieve.");
    }

    if (requestParameters.userId === null || requestParameters.userId === undefined) {
      throw new runtime.RequiredError("userId", "Required parameter requestParameters.userId was null or undefined when calling usersMessageThreadsRetrieve.");
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
      headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
    }
    const response = await this.request(
      {
        path: `/api/v1/users/{user_id}/message_threads/{id}/`
          .replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id)))
          .replace(`{${"user_id"}}`, encodeURIComponent(String(requestParameters.userId))),
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => ThreadFromJSON(jsonValue));
  }

  /**
   * This endpoint may be used for listing message threads for a given user. Its url is `/api/v1/users/{username}/message_threads/` and it accepts only GET method.
   */
  async usersMessageThreadsRetrieve(requestParameters: UsersMessageThreadsRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<Thread> {
    const response = await this.usersMessageThreadsRetrieveRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * This viewset is used to manage thread tags. This depends both on user and thread. Adding a new tag to a thread will automatically add it to the user (if not already exists).
   */
  async usersMessageThreadsTagsCreateRaw(
    requestParameters: UsersMessageThreadsTagsCreateRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<runtime.ApiResponse<ThreadTagWrite>> {
    if (requestParameters.threadId === null || requestParameters.threadId === undefined) {
      throw new runtime.RequiredError("threadId", "Required parameter requestParameters.threadId was null or undefined when calling usersMessageThreadsTagsCreate.");
    }

    if (requestParameters.userId === null || requestParameters.userId === undefined) {
      throw new runtime.RequiredError("userId", "Required parameter requestParameters.userId was null or undefined when calling usersMessageThreadsTagsCreate.");
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
      headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
    }
    const response = await this.request(
      {
        path: `/api/v1/users/{user_id}/message_threads/{thread_id}/tags/`
          .replace(`{${"thread_id"}}`, encodeURIComponent(String(requestParameters.threadId)))
          .replace(`{${"user_id"}}`, encodeURIComponent(String(requestParameters.userId))),
        method: "POST",
        headers: headerParameters,
        query: queryParameters,
        body: ThreadTagWriteRequestToJSON(requestParameters.request || requestParameters.threadTagWriteRequest),
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => ThreadTagWriteFromJSON(jsonValue));
  }

  /**
   * This viewset is used to manage thread tags. This depends both on user and thread. Adding a new tag to a thread will automatically add it to the user (if not already exists).
   */
  async usersMessageThreadsTagsCreate(requestParameters: UsersMessageThreadsTagsCreateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<ThreadTagWrite> {
    const response = await this.usersMessageThreadsTagsCreateRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * This viewset is used to manage thread tags. This depends both on user and thread. Adding a new tag to a thread will automatically add it to the user (if not already exists).
   */
  async usersMessageThreadsTagsDestroyRaw(
    requestParameters: UsersMessageThreadsTagsDestroyRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<runtime.ApiResponse<void>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError("id", "Required parameter requestParameters.id was null or undefined when calling usersMessageThreadsTagsDestroy.");
    }

    if (requestParameters.threadId === null || requestParameters.threadId === undefined) {
      throw new runtime.RequiredError("threadId", "Required parameter requestParameters.threadId was null or undefined when calling usersMessageThreadsTagsDestroy.");
    }

    if (requestParameters.userId === null || requestParameters.userId === undefined) {
      throw new runtime.RequiredError("userId", "Required parameter requestParameters.userId was null or undefined when calling usersMessageThreadsTagsDestroy.");
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
      headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
    }
    const response = await this.request(
      {
        path: `/api/v1/users/{user_id}/message_threads/{thread_id}/tags/{id}/`
          .replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id)))
          .replace(`{${"thread_id"}}`, encodeURIComponent(String(requestParameters.threadId)))
          .replace(`{${"user_id"}}`, encodeURIComponent(String(requestParameters.userId))),
        method: "DELETE",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    );

    return new runtime.VoidApiResponse(response);
  }

  /**
   * This viewset is used to manage thread tags. This depends both on user and thread. Adding a new tag to a thread will automatically add it to the user (if not already exists).
   */
  async usersMessageThreadsTagsDestroy(requestParameters: UsersMessageThreadsTagsDestroyRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<void> {
    await this.usersMessageThreadsTagsDestroyRaw(requestParameters, initOverrides);
  }

  /**
   * This viewset is used to manage thread tags. This depends both on user and thread. Adding a new tag to a thread will automatically add it to the user (if not already exists).
   */
  async usersMessageThreadsTagsListRaw(
    requestParameters: UsersMessageThreadsTagsListRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<runtime.ApiResponse<Array<ThreadTagRead>>> {
    if (requestParameters.threadId === null || requestParameters.threadId === undefined) {
      throw new runtime.RequiredError("threadId", "Required parameter requestParameters.threadId was null or undefined when calling usersMessageThreadsTagsList.");
    }

    if (requestParameters.userId === null || requestParameters.userId === undefined) {
      throw new runtime.RequiredError("userId", "Required parameter requestParameters.userId was null or undefined when calling usersMessageThreadsTagsList.");
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
      headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
    }
    const response = await this.request(
      {
        path: `/api/v1/users/{user_id}/message_threads/{thread_id}/tags/`
          .replace(`{${"thread_id"}}`, encodeURIComponent(String(requestParameters.threadId)))
          .replace(`{${"user_id"}}`, encodeURIComponent(String(requestParameters.userId))),
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ThreadTagReadFromJSON));
  }

  /**
   * This viewset is used to manage thread tags. This depends both on user and thread. Adding a new tag to a thread will automatically add it to the user (if not already exists).
   */
  async usersMessageThreadsTagsList(
    requestParameters: UsersMessageThreadsTagsListRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<Array<ThreadTagRead>> {
    const response = await this.usersMessageThreadsTagsListRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * This viewset is used to manage thread tags. This depends both on user and thread. Adding a new tag to a thread will automatically add it to the user (if not already exists).
   */
  async usersMessageThreadsTagsUpdateThreadUpdateRaw(
    requestParameters: UsersMessageThreadsTagsUpdateThreadUpdateRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<runtime.ApiResponse<TagAction>> {
    if (requestParameters.threadId === null || requestParameters.threadId === undefined) {
      throw new runtime.RequiredError("threadId", "Required parameter requestParameters.threadId was null or undefined when calling usersMessageThreadsTagsUpdateThreadUpdate.");
    }

    if (requestParameters.userId === null || requestParameters.userId === undefined) {
      throw new runtime.RequiredError("userId", "Required parameter requestParameters.userId was null or undefined when calling usersMessageThreadsTagsUpdateThreadUpdate.");
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
      headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
    }
    const response = await this.request(
      {
        path: `/api/v1/users/{user_id}/message_threads/{thread_id}/tags/update_thread/`
          .replace(`{${"thread_id"}}`, encodeURIComponent(String(requestParameters.threadId)))
          .replace(`{${"user_id"}}`, encodeURIComponent(String(requestParameters.userId))),
        method: "PUT",
        headers: headerParameters,
        query: queryParameters,
        body: TagActionRequestToJSON(requestParameters.request || requestParameters.tagActionRequest),
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => TagActionFromJSON(jsonValue));
  }

  /**
   * This viewset is used to manage thread tags. This depends both on user and thread. Adding a new tag to a thread will automatically add it to the user (if not already exists).
   */
  async usersMessageThreadsTagsUpdateThreadUpdate(
    requestParameters: UsersMessageThreadsTagsUpdateThreadUpdateRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<TagAction> {
    const response = await this.usersMessageThreadsTagsUpdateThreadUpdateRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * This endpoint may be used for listing message threads for a given user. Its url is `/api/v1/users/{username}/message_threads/` and it accepts only GET method.
   */
  async usersMessageThreadsUnseenThreadCountsRetrieveRaw(
    requestParameters: UsersMessageThreadsUnseenThreadCountsRetrieveRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<runtime.ApiResponse<UnseenThreadCounts>> {
    if (requestParameters.userId === null || requestParameters.userId === undefined) {
      throw new runtime.RequiredError("userId", "Required parameter requestParameters.userId was null or undefined when calling usersMessageThreadsUnseenThreadCountsRetrieve.");
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
      headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
    }
    const response = await this.request(
      {
        path: `/api/v1/users/{user_id}/message_threads/unseen_thread_counts/`.replace(`{${"user_id"}}`, encodeURIComponent(String(requestParameters.userId))),
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => UnseenThreadCountsFromJSON(jsonValue));
  }

  /**
   * This endpoint may be used for listing message threads for a given user. Its url is `/api/v1/users/{username}/message_threads/` and it accepts only GET method.
   */
  async usersMessageThreadsUnseenThreadCountsRetrieve(
    requestParameters: UsersMessageThreadsUnseenThreadCountsRetrieveRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<UnseenThreadCounts> {
    const response = await this.usersMessageThreadsUnseenThreadCountsRetrieveRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   */
  async usersOffspringGroupsListRaw(
    requestParameters: UsersOffspringGroupsListRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<runtime.ApiResponse<PaginatedOffspringGroupListList>> {
    if (requestParameters.userId === null || requestParameters.userId === undefined) {
      throw new runtime.RequiredError("userId", "Required parameter requestParameters.userId was null or undefined when calling usersOffspringGroupsList.");
    }

    const queryParameters: any = {};

    if (requestParameters.birthDate !== undefined) {
      queryParameters["birth_date"] = requestParameters.birthDate;
    }

    if (requestParameters.category !== undefined) {
      queryParameters["category"] = requestParameters.category;
    }

    if (requestParameters.child !== undefined) {
      queryParameters["child"] = requestParameters.child;
    }

    if (requestParameters.clutchEggCount !== undefined) {
      queryParameters["clutch_egg_count"] = requestParameters.clutchEggCount;
    }

    if (requestParameters.clutchEggCountBad !== undefined) {
      queryParameters["clutch_egg_count_bad"] = requestParameters.clutchEggCountBad;
    }

    if (requestParameters.clutchEggCountGood !== undefined) {
      queryParameters["clutch_egg_count_good"] = requestParameters.clutchEggCountGood;
    }

    if (requestParameters.clutchEstimatedHatchDate !== undefined) {
      queryParameters["clutch_estimated_hatch_date"] = requestParameters.clutchEstimatedHatchDate;
    }

    if (requestParameters.dam !== undefined) {
      queryParameters["dam"] = requestParameters.dam;
    }

    if (requestParameters.damId !== undefined) {
      queryParameters["dam_id"] = requestParameters.damId;
    }

    if (requestParameters.groupId !== undefined) {
      queryParameters["group_id"] = requestParameters.groupId;
    }

    if (requestParameters.offspringGroupId !== undefined) {
      queryParameters["offspring_group_id"] = requestParameters.offspringGroupId;
    }

    if (requestParameters.ordering !== undefined) {
      queryParameters["ordering"] = requestParameters.ordering;
    }

    if (requestParameters.page !== undefined) {
      queryParameters["page"] = requestParameters.page;
    }

    if (requestParameters.pageSize !== undefined) {
      queryParameters["page_size"] = requestParameters.pageSize;
    }

    if (requestParameters.parent !== undefined) {
      queryParameters["parent"] = requestParameters.parent;
    }

    if (requestParameters.pk !== undefined) {
      queryParameters["pk"] = requestParameters.pk;
    }

    if (requestParameters.search !== undefined) {
      queryParameters["search"] = requestParameters.search;
    }

    if (requestParameters.season !== undefined) {
      queryParameters["season"] = requestParameters.season;
    }

    if (requestParameters.sire !== undefined) {
      queryParameters["sire"] = requestParameters.sire;
    }

    if (requestParameters.sireId !== undefined) {
      queryParameters["sire_id"] = requestParameters.sireId;
    }

    if (requestParameters.status !== undefined) {
      queryParameters["status"] = requestParameters.status;
    }

    if (requestParameters.traitsIn !== undefined) {
      queryParameters["traits__in"] = requestParameters.traitsIn;
    }

    if (requestParameters.traitsNotIn !== undefined) {
      queryParameters["traits__not__in"] = requestParameters.traitsNotIn;
    }

    if (requestParameters.visibility !== undefined) {
      queryParameters["visibility"] = requestParameters.visibility;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
      headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
    }
    const response = await this.request(
      {
        path: `/api/v1/users/{user_id}/offspring_groups/`.replace(`{${"user_id"}}`, encodeURIComponent(String(requestParameters.userId))),
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => PaginatedOffspringGroupListListFromJSON(jsonValue));
  }

  /**
   */
  async usersOffspringGroupsList(
    requestParameters: UsersOffspringGroupsListRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<PaginatedOffspringGroupListList> {
    const response = await this.usersOffspringGroupsListRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   */
  async usersOffspringGroupsRetrieveRaw(
    requestParameters: UsersOffspringGroupsRetrieveRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<runtime.ApiResponse<OffspringGroup>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError("id", "Required parameter requestParameters.id was null or undefined when calling usersOffspringGroupsRetrieve.");
    }

    if (requestParameters.userId === null || requestParameters.userId === undefined) {
      throw new runtime.RequiredError("userId", "Required parameter requestParameters.userId was null or undefined when calling usersOffspringGroupsRetrieve.");
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
      headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
    }
    const response = await this.request(
      {
        path: `/api/v1/users/{user_id}/offspring_groups/{id}/`
          .replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id)))
          .replace(`{${"user_id"}}`, encodeURIComponent(String(requestParameters.userId))),
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => OffspringGroupFromJSON(jsonValue));
  }

  /**
   */
  async usersOffspringGroupsRetrieve(requestParameters: UsersOffspringGroupsRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<OffspringGroup> {
    const response = await this.usersOffspringGroupsRetrieveRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   */
  async usersPreferencesPartialUpdateRaw(
    requestParameters: UsersPreferencesPartialUpdateRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<runtime.ApiResponse<UserModelPreferences>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
      headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
    }
    const response = await this.request(
      {
        path: `/api/v1/users/preferences/`,
        method: "PATCH",
        headers: headerParameters,
        query: queryParameters,
        body: PatchedUserModelPreferencesRequestToJSON(requestParameters.request || requestParameters.patchedUserModelPreferencesRequest),
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => UserModelPreferencesFromJSON(jsonValue));
  }

  /**
   */
  async usersPreferencesPartialUpdate(
    requestParameters: UsersPreferencesPartialUpdateRequest = {},
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<UserModelPreferences> {
    const response = await this.usersPreferencesPartialUpdateRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * This viewset is used to manage user-available tags. This is only user related and does not depend on any thread. This makes it possible to create a tag for a user and not add it to any thread.
   */
  async usersThreadTagsCreateRaw(
    requestParameters: UsersThreadTagsCreateRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<runtime.ApiResponse<UserThreadTag>> {
    if (requestParameters.userId === null || requestParameters.userId === undefined) {
      throw new runtime.RequiredError("userId", "Required parameter requestParameters.userId was null or undefined when calling usersThreadTagsCreate.");
    }

    if ((requestParameters.request || requestParameters.userThreadTagRequest) === null || (requestParameters.request || requestParameters.userThreadTagRequest) === undefined) {
      throw new runtime.RequiredError(
        "userThreadTagRequest",
        "Required parameter (requestParameters.request || requestParameters.userThreadTagRequest) was null or undefined when calling usersThreadTagsCreate."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
      headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
    }
    const response = await this.request(
      {
        path: `/api/v1/users/{user_id}/thread_tags/`.replace(`{${"user_id"}}`, encodeURIComponent(String(requestParameters.userId))),
        method: "POST",
        headers: headerParameters,
        query: queryParameters,
        body: UserThreadTagRequestToJSON(requestParameters.request || requestParameters.userThreadTagRequest),
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => UserThreadTagFromJSON(jsonValue));
  }

  /**
   * This viewset is used to manage user-available tags. This is only user related and does not depend on any thread. This makes it possible to create a tag for a user and not add it to any thread.
   */
  async usersThreadTagsCreate(requestParameters: UsersThreadTagsCreateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<UserThreadTag> {
    const response = await this.usersThreadTagsCreateRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * This viewset is used to manage user-available tags. This is only user related and does not depend on any thread. This makes it possible to create a tag for a user and not add it to any thread.
   */
  async usersThreadTagsDestroyRaw(requestParameters: UsersThreadTagsDestroyRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<void>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError("id", "Required parameter requestParameters.id was null or undefined when calling usersThreadTagsDestroy.");
    }

    if (requestParameters.userId === null || requestParameters.userId === undefined) {
      throw new runtime.RequiredError("userId", "Required parameter requestParameters.userId was null or undefined when calling usersThreadTagsDestroy.");
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
      headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
    }
    const response = await this.request(
      {
        path: `/api/v1/users/{user_id}/thread_tags/{id}/`
          .replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id)))
          .replace(`{${"user_id"}}`, encodeURIComponent(String(requestParameters.userId))),
        method: "DELETE",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    );

    return new runtime.VoidApiResponse(response);
  }

  /**
   * This viewset is used to manage user-available tags. This is only user related and does not depend on any thread. This makes it possible to create a tag for a user and not add it to any thread.
   */
  async usersThreadTagsDestroy(requestParameters: UsersThreadTagsDestroyRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<void> {
    await this.usersThreadTagsDestroyRaw(requestParameters, initOverrides);
  }

  /**
   * This viewset is used to manage user-available tags. This is only user related and does not depend on any thread. This makes it possible to create a tag for a user and not add it to any thread.
   */
  async usersThreadTagsListRaw(
    requestParameters: UsersThreadTagsListRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<runtime.ApiResponse<Array<UserThreadTag>>> {
    if (requestParameters.userId === null || requestParameters.userId === undefined) {
      throw new runtime.RequiredError("userId", "Required parameter requestParameters.userId was null or undefined when calling usersThreadTagsList.");
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
      headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
    }
    const response = await this.request(
      {
        path: `/api/v1/users/{user_id}/thread_tags/`.replace(`{${"user_id"}}`, encodeURIComponent(String(requestParameters.userId))),
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(UserThreadTagFromJSON));
  }

  /**
   * This viewset is used to manage user-available tags. This is only user related and does not depend on any thread. This makes it possible to create a tag for a user and not add it to any thread.
   */
  async usersThreadTagsList(requestParameters: UsersThreadTagsListRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<Array<UserThreadTag>> {
    const response = await this.usersThreadTagsListRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * This viewset is used to manage user-available tags. This is only user related and does not depend on any thread. This makes it possible to create a tag for a user and not add it to any thread.
   */
  async usersThreadTagsPartialUpdateRaw(
    requestParameters: UsersThreadTagsPartialUpdateRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<runtime.ApiResponse<UserThreadTag>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError("id", "Required parameter requestParameters.id was null or undefined when calling usersThreadTagsPartialUpdate.");
    }

    if (requestParameters.userId === null || requestParameters.userId === undefined) {
      throw new runtime.RequiredError("userId", "Required parameter requestParameters.userId was null or undefined when calling usersThreadTagsPartialUpdate.");
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
      headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
    }
    const response = await this.request(
      {
        path: `/api/v1/users/{user_id}/thread_tags/{id}/`
          .replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id)))
          .replace(`{${"user_id"}}`, encodeURIComponent(String(requestParameters.userId))),
        method: "PATCH",
        headers: headerParameters,
        query: queryParameters,
        body: PatchedUserThreadTagRequestToJSON(requestParameters.request || requestParameters.patchedUserThreadTagRequest),
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => UserThreadTagFromJSON(jsonValue));
  }

  /**
   * This viewset is used to manage user-available tags. This is only user related and does not depend on any thread. This makes it possible to create a tag for a user and not add it to any thread.
   */
  async usersThreadTagsPartialUpdate(requestParameters: UsersThreadTagsPartialUpdateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<UserThreadTag> {
    const response = await this.usersThreadTagsPartialUpdateRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * This viewset is used to manage user-available tags. This is only user related and does not depend on any thread. This makes it possible to create a tag for a user and not add it to any thread.
   */
  async usersThreadTagsRetrieveRaw(
    requestParameters: UsersThreadTagsRetrieveRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<runtime.ApiResponse<UserThreadTag>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError("id", "Required parameter requestParameters.id was null or undefined when calling usersThreadTagsRetrieve.");
    }

    if (requestParameters.userId === null || requestParameters.userId === undefined) {
      throw new runtime.RequiredError("userId", "Required parameter requestParameters.userId was null or undefined when calling usersThreadTagsRetrieve.");
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
      headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
    }
    const response = await this.request(
      {
        path: `/api/v1/users/{user_id}/thread_tags/{id}/`
          .replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id)))
          .replace(`{${"user_id"}}`, encodeURIComponent(String(requestParameters.userId))),
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => UserThreadTagFromJSON(jsonValue));
  }

  /**
   * This viewset is used to manage user-available tags. This is only user related and does not depend on any thread. This makes it possible to create a tag for a user and not add it to any thread.
   */
  async usersThreadTagsRetrieve(requestParameters: UsersThreadTagsRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<UserThreadTag> {
    const response = await this.usersThreadTagsRetrieveRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * This viewset is used to manage user-available tags. This is only user related and does not depend on any thread. This makes it possible to create a tag for a user and not add it to any thread.
   */
  async usersThreadTagsUpdateRaw(
    requestParameters: UsersThreadTagsUpdateRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<runtime.ApiResponse<UserThreadTag>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError("id", "Required parameter requestParameters.id was null or undefined when calling usersThreadTagsUpdate.");
    }

    if (requestParameters.userId === null || requestParameters.userId === undefined) {
      throw new runtime.RequiredError("userId", "Required parameter requestParameters.userId was null or undefined when calling usersThreadTagsUpdate.");
    }

    if ((requestParameters.request || requestParameters.userThreadTagRequest) === null || (requestParameters.request || requestParameters.userThreadTagRequest) === undefined) {
      throw new runtime.RequiredError(
        "userThreadTagRequest",
        "Required parameter (requestParameters.request || requestParameters.userThreadTagRequest) was null or undefined when calling usersThreadTagsUpdate."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
      headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
    }
    const response = await this.request(
      {
        path: `/api/v1/users/{user_id}/thread_tags/{id}/`
          .replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id)))
          .replace(`{${"user_id"}}`, encodeURIComponent(String(requestParameters.userId))),
        method: "PUT",
        headers: headerParameters,
        query: queryParameters,
        body: UserThreadTagRequestToJSON(requestParameters.request || requestParameters.userThreadTagRequest),
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => UserThreadTagFromJSON(jsonValue));
  }

  /**
   * This viewset is used to manage user-available tags. This is only user related and does not depend on any thread. This makes it possible to create a tag for a user and not add it to any thread.
   */
  async usersThreadTagsUpdate(requestParameters: UsersThreadTagsUpdateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<UserThreadTag> {
    const response = await this.usersThreadTagsUpdateRaw(requestParameters, initOverrides);
    return await response.value();
  }
}

/**
 * @export
 */
export const UsersBreedingPairingByRetrieveSexEnum = {
  Female: "female",
  Male: "male",
} as const;
export type UsersBreedingPairingByRetrieveSexEnum = (typeof UsersBreedingPairingByRetrieveSexEnum)[keyof typeof UsersBreedingPairingByRetrieveSexEnum];
/**
 * @export
 */
export const UsersBreedingPairingByRetrieveOrderingEnum = {
  AssignedCount: "assigned_count",
  Category: "category",
  OffspringGroup: "offspring_group",
  RecentPairingDate: "recent_pairing_date",
  Season: "season",
  Title: "title",
} as const;
export type UsersBreedingPairingByRetrieveOrderingEnum = (typeof UsersBreedingPairingByRetrieveOrderingEnum)[keyof typeof UsersBreedingPairingByRetrieveOrderingEnum];
/**
 * @export
 */
export const UsersBreedingPairingListByEnum = {
  Female: "female",
  Male: "male",
} as const;
export type UsersBreedingPairingListByEnum = (typeof UsersBreedingPairingListByEnum)[keyof typeof UsersBreedingPairingListByEnum];
/**
 * @export
 */
export const UsersBreedingPairingListOrderingEnum = {
  AssignedCount: "assigned_count",
  BreedingPairingVisibility: "breeding_pairing_visibility",
  Category: "category",
  HasImg: "has_img",
  OffspringGroup: "offspring_group",
  OlderPairingDate: "older_pairing_date",
  OtherAnimalHasImg: "other_animal_has_img",
  OtherAnimalTitle: "other_animal_title",
  RecentPairingDate: "recent_pairing_date",
  Season: "season",
  Title: "title",
} as const;
export type UsersBreedingPairingListOrderingEnum = (typeof UsersBreedingPairingListOrderingEnum)[keyof typeof UsersBreedingPairingListOrderingEnum];
/**
 * @export
 */
export const UsersBreedingPairingListStatusEnum = {
  ActiveCollection: "active_collection",
  All: "all",
  Archived: "archived",
  Breeders: "breeders",
  Collection: "collection",
  CurrentInventory: "current_inventory",
  Expired: "expired",
  ForSale: "for_sale",
  Holdbacks: "holdbacks",
  Inactive: "inactive",
  LiveListings: "live_listings",
  Loaned: "loaned",
  Nfs: "nfs",
  NotActiveCollection: "not_active_collection",
  NotLiveListings: "not_live_listings",
  OnHold: "on_hold",
  Pets: "pets",
  Purchased: "purchased",
  Sold: "sold",
} as const;
export type UsersBreedingPairingListStatusEnum = (typeof UsersBreedingPairingListStatusEnum)[keyof typeof UsersBreedingPairingListStatusEnum];
/**
 * @export
 */
export const UsersCollectionListActiveEnum = {
  Active: "active",
  Expired: "expired",
  Inactive: "inactive",
} as const;
export type UsersCollectionListActiveEnum = (typeof UsersCollectionListActiveEnum)[keyof typeof UsersCollectionListActiveEnum];
/**
 * @export
 */
export const UsersCollectionListMaturityEnum = {
  Adult: "adult",
  Baby: "baby",
  Juvenile: "juvenile",
  NotReady: "not_ready",
  Subadult: "subadult",
} as const;
export type UsersCollectionListMaturityEnum = (typeof UsersCollectionListMaturityEnum)[keyof typeof UsersCollectionListMaturityEnum];
/**
 * @export
 */
export const UsersCollectionListOrderingEnum = {
  Image: "image",
  Title: "title",
  BirthDate: "birth_date",
  Sex: "sex",
  Maturity: "maturity",
  Category: "category",
  Weight: "weight",
  Price: "price",
  WholesalePrice: "wholesale_price",
  LastUpdated: "last_updated",
  Traits: "traits",
  Quantity: "quantity",
  AnimalId: "animal_id",
  GroupId: "group_id",
  Status: "status",
  Visibility: "visibility",
  Active: "active",
  Clicks: "clicks",
  Impressions: "impressions",
  Inquiries: "inquiries",
  TitleId: "title_id",
  Dams: "dams",
  Sires: "sires",
  Tags: "tags",
  LastRenewal: "last_renewal",
  ImagesFirst: "images_first",
  AvailableForOrder: "available_for_order",
  AcceptOffers: "accept_offers",
  TransferredFrom: "transferred_from",
  Image: "-image",
  Title: "-title",
  BirthDate: "-birth_date",
  Sex: "-sex",
  Maturity: "-maturity",
  Category: "-category",
  Weight: "-weight",
  Price: "-price",
  WholesalePrice: "-wholesale_price",
  LastUpdated: "-last_updated",
  Traits: "-traits",
  Quantity: "-quantity",
  AnimalId: "-animal_id",
  GroupId: "-group_id",
  Status: "-status",
  Visibility: "-visibility",
  Active: "-active",
  Clicks: "-clicks",
  Impressions: "-impressions",
  Inquiries: "-inquiries",
  TitleId: "-title_id",
  Dams: "-dams",
  Sires: "-sires",
  Tags: "-tags",
  LastRenewal: "-last_renewal",
  ImagesFirst: "-images_first",
  AvailableForOrder: "-available_for_order",
  AcceptOffers: "-accept_offers",
  TransferredFrom: "-transferred_from",
} as const;
export type UsersCollectionListOrderingEnum = (typeof UsersCollectionListOrderingEnum)[keyof typeof UsersCollectionListOrderingEnum];
/**
 * @export
 */
export const UsersCollectionListPreyFoodEnum = {
  Chicken: "chicken",
  Cricket: "cricket",
  DryFeed: "dry-feed",
  Goat: "goat",
  GuineaPig: "guinea-pig",
  Lamb: "lamb",
  MealReplacement: "meal-replacement",
  Mealworm: "mealworm",
  Mouse: "mouse",
  Other: "other",
  Pig: "pig",
  Quail: "quail",
  Rabbit: "rabbit",
  Rat: "rat",
  Roach: "roach",
  SoftFurredRat: "soft-furred-rat",
  Unknown: "unknown",
  Vegetables: "vegetables",
} as const;
export type UsersCollectionListPreyFoodEnum = (typeof UsersCollectionListPreyFoodEnum)[keyof typeof UsersCollectionListPreyFoodEnum];
/**
 * @export
 */
export const UsersCollectionListPreyStatusEnum = {
  Frozen: "frozen",
  Live: "live",
  Prekilled: "prekilled",
  Unknown: "unknown",
} as const;
export type UsersCollectionListPreyStatusEnum = (typeof UsersCollectionListPreyStatusEnum)[keyof typeof UsersCollectionListPreyStatusEnum];
/**
 * @export
 */
export const UsersCollectionListPriceCurrencyEnum = {
  Aed: "AED",
  Afn: "AFN",
  All: "ALL",
  Amd: "AMD",
  Ang: "ANG",
  Aoa: "AOA",
  Ars: "ARS",
  Aud: "AUD",
  Awg: "AWG",
  Azn: "AZN",
  Bam: "BAM",
  Bbd: "BBD",
  Bdt: "BDT",
  Bgn: "BGN",
  Bhd: "BHD",
  Bif: "BIF",
  Bmd: "BMD",
  Bnd: "BND",
  Brl: "BRL",
  Bsd: "BSD",
  Btn: "BTN",
  Bwp: "BWP",
  Byr: "BYR",
  Bzd: "BZD",
  Cad: "CAD",
  Cdf: "CDF",
  Chf: "CHF",
  Clp: "CLP",
  Cny: "CNY",
  Cop: "COP",
  Crc: "CRC",
  Cuc: "CUC",
  Cup: "CUP",
  Cve: "CVE",
  Czk: "CZK",
  Djf: "DJF",
  Dkk: "DKK",
  Dop: "DOP",
  Dzd: "DZD",
  Egp: "EGP",
  Ern: "ERN",
  Etb: "ETB",
  Eur: "EUR",
  Fjd: "FJD",
  Fkp: "FKP",
  Gbp: "GBP",
  Gel: "GEL",
  Ghs: "GHS",
  Gip: "GIP",
  Gmd: "GMD",
  Gnf: "GNF",
  Gtq: "GTQ",
  Gyd: "GYD",
  Hkd: "HKD",
  Hnl: "HNL",
  Hrk: "HRK",
  Htg: "HTG",
  Huf: "HUF",
  Idr: "IDR",
  Ils: "ILS",
  Imp: "IMP",
  Inr: "INR",
  Iqd: "IQD",
  Irr: "IRR",
  Isk: "ISK",
  Jmd: "JMD",
  Jod: "JOD",
  Jpy: "JPY",
  Kes: "KES",
  Kgs: "KGS",
  Khr: "KHR",
  Kmf: "KMF",
  Kpw: "KPW",
  Krw: "KRW",
  Kwd: "KWD",
  Kyd: "KYD",
  Kzt: "KZT",
  Lak: "LAK",
  Lbp: "LBP",
  Lkr: "LKR",
  Lrd: "LRD",
  Lsl: "LSL",
  Ltl: "LTL",
  Lvl: "LVL",
  Lyd: "LYD",
  Mad: "MAD",
  Mdl: "MDL",
  Mga: "MGA",
  Mkd: "MKD",
  Mmk: "MMK",
  Mnt: "MNT",
  Mop: "MOP",
  Mro: "MRO",
  Mur: "MUR",
  Mvr: "MVR",
  Mwk: "MWK",
  Mxn: "MXN",
  Myr: "MYR",
  Mzn: "MZN",
  Nad: "NAD",
  Ngn: "NGN",
  Nio: "NIO",
  Nok: "NOK",
  Npr: "NPR",
  Nzd: "NZD",
  Omr: "OMR",
  Pen: "PEN",
  Pgk: "PGK",
  Php: "PHP",
  Pkr: "PKR",
  Pln: "PLN",
  Pyg: "PYG",
  Qar: "QAR",
  Ron: "RON",
  Rsd: "RSD",
  Rub: "RUB",
  Rwf: "RWF",
  Sar: "SAR",
  Sbd: "SBD",
  Scr: "SCR",
  Sdg: "SDG",
  Sek: "SEK",
  Sgd: "SGD",
  Shp: "SHP",
  Sll: "SLL",
  Sos: "SOS",
  Srd: "SRD",
  Std: "STD",
  Syp: "SYP",
  Szl: "SZL",
  Thb: "THB",
  Tjs: "TJS",
  Tnd: "TND",
  Top: "TOP",
  Try: "TRY",
  Ttd: "TTD",
  Twd: "TWD",
  Tzs: "TZS",
  Uah: "UAH",
  Ugx: "UGX",
  Usd: "USD",
  Uzs: "UZS",
  Vef: "VEF",
  Vnd: "VND",
  Vuv: "VUV",
  Wst: "WST",
  Xaf: "XAF",
  Xag: "XAG",
  Xcd: "XCD",
  Xdr: "XDR",
  Xof: "XOF",
  Xpd: "XPD",
  Xpf: "XPF",
  Xpt: "XPT",
  Yer: "YER",
  Zar: "ZAR",
  Zmk: "ZMK",
  Zmw: "ZMW",
  Zwl: "ZWL",
} as const;
export type UsersCollectionListPriceCurrencyEnum = (typeof UsersCollectionListPriceCurrencyEnum)[keyof typeof UsersCollectionListPriceCurrencyEnum];
/**
 * @export
 */
export const UsersCollectionListSexEnum = {
  Female: "female",
  Male: "male",
  Mixed: "mixed",
  Unknown: "unknown",
} as const;
export type UsersCollectionListSexEnum = (typeof UsersCollectionListSexEnum)[keyof typeof UsersCollectionListSexEnum];
/**
 * @export
 */
export const UsersCollectionListStateEnum = {
  Archived: "archived",
  Breeders: "breeders",
  ForSale: "for_sale",
  Holdbacks: "holdbacks",
  Loaned: "loaned",
  Nfs: "nfs",
  OnHold: "on_hold",
  OnHoldAuction: "on_hold_auction",
  Pets: "pets",
  Sold: "sold",
  SoldAuction: "sold_auction",
} as const;
export type UsersCollectionListStateEnum = (typeof UsersCollectionListStateEnum)[keyof typeof UsersCollectionListStateEnum];
/**
 * @export
 */
export const UsersCollectionListStatusEnum = {
  ActiveCollection: "active_collection",
  All: "all",
  Archived: "archived",
  Breeders: "breeders",
  Collection: "collection",
  CurrentInventory: "current_inventory",
  Expired: "expired",
  ForSale: "for_sale",
  Holdbacks: "holdbacks",
  Inactive: "inactive",
  LiveListings: "live_listings",
  Loaned: "loaned",
  NeedsUpdate: "needs_update",
  Nfs: "nfs",
  NotActiveCollection: "not_active_collection",
  NotLiveListings: "not_live_listings",
  OnHold: "on_hold",
  Pets: "pets",
  Purchased: "purchased",
  Sold: "sold",
} as const;
export type UsersCollectionListStatusEnum = (typeof UsersCollectionListStatusEnum)[keyof typeof UsersCollectionListStatusEnum];
/**
 * @export
 */
export const UsersCollectionListTransferredEnum = {
  NotTransferred: "not_transferred",
  Transferred: "transferred",
} as const;
export type UsersCollectionListTransferredEnum = (typeof UsersCollectionListTransferredEnum)[keyof typeof UsersCollectionListTransferredEnum];
/**
 * @export
 */
export const UsersCollectionListVisibilityEnum = {
  Private: "private",
  Public: "public",
  Unlisted: "unlisted",
} as const;
export type UsersCollectionListVisibilityEnum = (typeof UsersCollectionListVisibilityEnum)[keyof typeof UsersCollectionListVisibilityEnum];
/**
 * @export
 */
export const UsersMessageThreadsListMessageCollectionTypeEnum = {
  All: "all",
  Archive: "archive",
  Inbox: "inbox",
} as const;
export type UsersMessageThreadsListMessageCollectionTypeEnum =
  (typeof UsersMessageThreadsListMessageCollectionTypeEnum)[keyof typeof UsersMessageThreadsListMessageCollectionTypeEnum];
/**
 * @export
 */
export const UsersMessageThreadsListMessageTypeEnum = {
  Archived: "is_archived",
  Auction: "is_auction",
  Blocked: "is_blocked",
  Closed: "is_closed",
  Customer: "is_customer",
  OnHold: "is_on_hold",
  Purchased: "is_purchased",
  Sold: "is_sold",
} as const;
export type UsersMessageThreadsListMessageTypeEnum = (typeof UsersMessageThreadsListMessageTypeEnum)[keyof typeof UsersMessageThreadsListMessageTypeEnum];
/**
 * @export
 */
export const UsersOffspringGroupsListBirthDateEnum = {
  Today: "today",
  Yesterday: "yesterday",
  Week: "week",
  Month: "month",
  Year: "year",
} as const;
export type UsersOffspringGroupsListBirthDateEnum = (typeof UsersOffspringGroupsListBirthDateEnum)[keyof typeof UsersOffspringGroupsListBirthDateEnum];
/**
 * @export
 */
export const UsersOffspringGroupsListClutchEstimatedHatchDateEnum = {
  Today: "today",
  Yesterday: "yesterday",
  Week: "week",
  Month: "month",
  Year: "year",
} as const;
export type UsersOffspringGroupsListClutchEstimatedHatchDateEnum =
  (typeof UsersOffspringGroupsListClutchEstimatedHatchDateEnum)[keyof typeof UsersOffspringGroupsListClutchEstimatedHatchDateEnum];
/**
 * @export
 */
export const UsersOffspringGroupsListOrderingEnum = {
  Modified: "modified",
  Image: "image",
  GroupId: "group_id",
  Category: "category",
  Notes: "notes",
  Dams: "dams",
  Sires: "sires",
  LayDate: "lay_date",
  Eggs: "eggs",
  ViableEggs: "viable_eggs",
  NonViableEggs: "non_viable_eggs",
  BirthDate: "birth_date",
  Children: "children",
  Visibility: "visibility",
  Modified: "-modified",
  Image: "-image",
  GroupId: "-group_id",
  Category: "-category",
  Notes: "-notes",
  Dams: "-dams",
  Sires: "-sires",
  LayDate: "-lay_date",
  Eggs: "-eggs",
  ViableEggs: "-viable_eggs",
  NonViableEggs: "-non_viable_eggs",
  BirthDate: "-birth_date",
  Children: "-children",
  Visibility: "-visibility",
} as const;
export type UsersOffspringGroupsListOrderingEnum = (typeof UsersOffspringGroupsListOrderingEnum)[keyof typeof UsersOffspringGroupsListOrderingEnum];
/**
 * @export
 */
export const UsersOffspringGroupsListStatusEnum = {
  Current: "current",
  Gravid: "gravid",
  Incubation: "incubation",
  Ytd: "ytd",
  LastYear: "last_year",
} as const;
export type UsersOffspringGroupsListStatusEnum = (typeof UsersOffspringGroupsListStatusEnum)[keyof typeof UsersOffspringGroupsListStatusEnum];
/**
 * @export
 */
export const UsersOffspringGroupsListVisibilityEnum = {
  Public: "public",
  Unlisted: "unlisted",
  Private: "private",
} as const;
export type UsersOffspringGroupsListVisibilityEnum = (typeof UsersOffspringGroupsListVisibilityEnum)[keyof typeof UsersOffspringGroupsListVisibilityEnum];
