import { Components, Theme } from '@mui/material';
import { colors } from '../constants';

const { gray200 } = colors;

export const MuiInputLabel: Components<Omit<Theme, 'components'>>['MuiInputLabel'] = {
  styleOverrides: {
    root: {
      backgroundColor: 'transparent',
      color: gray200,
      transform: 'translate(14px, 8px) scale(1)',
      '&.Mui-focused': {
        color: gray200,
      },
    },
    shrink: {
      transform: 'translate(14px, -9px) scale(0.75)',
    },
  },
};
